import { ConfiguraDayParaDataService } from 'app/shared/services';
import { Injectable } from '@angular/core';
import moment from 'moment';

export abstract class IRetornaRangeEntreDatasService {
    abstract execute(dataInicio: string, dataFim: string): any;
}

@Injectable()
export class RetornaRangeEntreDatasService implements IRetornaRangeEntreDatasService {
    constructor(readonly configuraDayParaDataService: ConfiguraDayParaDataService) {}
    execute(inicio: string, fim: string): any {
        let diasBuscar = 3;
        const dataInicio = new Date(inicio);
        const dataFim = new Date(fim);
        const diferenca = Math.abs(dataInicio.getTime() - dataFim.getTime());
        const diferencaDias = Math.ceil(diferenca / (1000 * 3600 * 24));
        let instantes = [];
        let instantesFormatados = [];
        if (diferencaDias <= 6) {
            return (instantesFormatados = [
                {
                    dataInicio: moment(inicio),
                    dataFim: moment(fim),
                },
            ]);
        }
        if (diferencaDias > 6) {
            instantes = [
                {
                    dataInicio: this.configuraDayParaDataService.execute(moment(inicio)),
                    dataFim: this.configuraDayParaDataService.execute(
                        moment(inicio).add(diasBuscar, 'days').hour(23).minute(59)
                    ),
                },
            ];
            for (let i = 1; i <= Math.ceil(diferencaDias / diasBuscar); ++i) {
                instantes.push({
                    dataInicio: this.configuraDayParaDataService.execute(
                        moment(instantes[i - 1].dataFim).add(1, 'minute')
                    ),
                    dataFim: this.configuraDayParaDataService.execute(
                        moment(instantes[i - 1].dataFim).add(diasBuscar, 'days')
                    ),
                });
            }
        }
        instantesFormatados = instantes.filter((data) => data.dataFim <= fim);
        instantesFormatados.push({
            dataInicio: this.configuraDayParaDataService.execute(
                moment(instantesFormatados[instantesFormatados.length - 1].dataFim).add(1, 'minute')
            ),
            dataFim: fim,
        });
        return instantesFormatados;
    }
}
