import { ObjetoGernericoParaSelectModel } from 'app/shared/models';
import { EstadoComunicacoPropriedadeEnum } from 'app/shared/enums';

export class PenduloPropriedadesModel {
    id: number;
    digitalTermopar: string;
    equipamento: ObjetoGernericoParaSelectModel;
    nivelSensor: number;
    nome: string;
    quantidadeSensores: number;
    statusComunicacao: EstadoComunicacoPropriedadeEnum;
}
