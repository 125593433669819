import { TipoBlocoRegraEnum } from 'app/shared/enums';

export class BlocoFormulasModel {
    formulas?: string[];
    tipoBloco?: TipoBlocoRegraEnum;
    blocoFilho?: BlocoFormulasModel[];

    constructor(partial?: Partial<BlocoFormulasModel>) {
        Object.assign(this, partial);
    }
}
