import { Injectable } from '@angular/core';

export abstract class ICopiaObjetoService {
    abstract execute(objetoOriginal: any): any;
}
@Injectable({ providedIn: 'root' })
export class CopiaObjetoService implements ICopiaObjetoService {
    execute(objetoOriginal: any): any {
        let copia = objetoOriginal instanceof Array ? [] : {};
        for (let i in objetoOriginal) {
            if (objetoOriginal[i] && typeof objetoOriginal[i] == 'object') copia[i] = this.execute(objetoOriginal[i]);
            else copia[i] = objetoOriginal[i];
        }
        return copia;
    }
}
