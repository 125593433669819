import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MarkdownModule } from 'ngx-markdown';
import { NgxMaskModule } from 'ngx-mask';
import { ControleAcoesHistoricoModule } from './shared/components/controle-acoes-historico/controle-acoes-historico.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoModule } from '@ngneat/transloco';

registerLocaleData(localePt);

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

class REMOVED_LABELS extends MatPaginatorIntl {
    nextPageLabel: string = '';
    previousPageLabel: string = '';
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        CoreModule,

        LayoutModule,

        MarkdownModule.forRoot({}),

        NgxMaskModule.forRoot({ validation: true }),
        NgxCaptchaModule,
        ControleAcoesHistoricoModule,
        HttpClientModule,
        TranslocoRootModule,
        TranslocoModule
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: MatPaginatorIntl, useValue: new REMOVED_LABELS() },
    ],
})
export class AppModule {}
