import { ProdutoModel } from 'app/shared/models';

export class AmostragemModel {
    id: number;
    estrutura_amostragem_ref: number;
    estrutura_amostragem_tipo: string;
    data_amostra: string;
    notas: string;
    umidade_controle: number;
    impureza_controle: number;
    avariados_controle: number;
    usuario_ref: number;
    arquivado: boolean;
    produto: ProdutoModel;

    constructor(partial?: Partial<AmostragemModel>) {
        Object.assign(this, partial);
    }
}
