import { Injectable } from '@angular/core';
import { EquipamentoInformacoesSiloInterface } from 'app/shared/interfaces';

export abstract class IFormataEquipamentosComponenteDataService {
    abstract execute(equipamentos: any): EquipamentoInformacoesSiloInterface[];
}

@Injectable({ providedIn: 'root' })
export class FormataEquipamentosComponenteDataService implements IFormataEquipamentosComponenteDataService {
    public execute(equipamentos: any): EquipamentoInformacoesSiloInterface[] {
        if (!equipamentos) return;

        let listaEquipamentos: any[] = [];

        equipamentos.forEach((equipamento) => {
            listaEquipamentos.push({
                id: equipamento?.id ?? equipamento?.coletor_equipamento_ref,
                nome: equipamento.nome,
                status:
                    equipamento?.status ??
                    equipamento?.comunicacao?.status ??
                    equipamento?.equipamento_comunicacao?.status,
                instante:
                    equipamento?.instante ??
                    equipamento?.comunicacao?.instante ??
                    equipamento?.equipamento_comunicacao?.instante,
                equipamento_tipo: equipamento.equipamento_tipo,
            });
        });

        return listaEquipamentos;
    }
}
