import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';
import { PrimeiroAcessoResponseModel } from 'app/shared/models';

export abstract class IUsuarioController {
    abstract verificarPrimeiroAcesso(token_hash: string): Observable<PrimeiroAcessoResponseModel>;
    abstract buscaUsuarioGlobal(id: number): Observable<any>;
}

@Injectable({
    providedIn: 'root',
})
export class UsuarioController implements IUsuarioController {
    private transport: IApiTransportInterface;

    constructor(readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/usuario');
    }

    verificarPrimeiroAcesso(token_hash: string): Observable<any> {
        return this.transport.http.get<any>(`${this.transport.path}/token/${token_hash}`);
    }
    buscaUsuarioGlobal(id: number): Observable<any> {
        return this.transport.http.get<any>(`${this.transport.path}/global-ref/${id}`);
    }
}
