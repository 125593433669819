import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { IObjectToAnyService } from 'app/shared/services';

export abstract class IListagemProgramasController {
    abstract buscaProgramas(): Observable<any>;
}

@Injectable({
    providedIn: 'root',
})
export class ListagemProgramasController implements IListagemProgramasController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/programa-aeracao');
    }

    buscaProgramas(): Observable<any> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: 1,
            limit: 100,
            ...{ 'filter.arquivado': 'false' },
        });
        return this.transport.http.get(`${this.transport.path}`, this.transport.options);
    }
}
