export enum NotificacaoAlterarEstoqueNivelEnum {
    SUCESSO = 'Alteração de nível realizada com sucesso!',
    ERRO = 'Não foi possível alterar o nível, por favor tente novamente.',
}

export enum NotificacaoRegistroAmostragemEnum {
    SUCESSO = 'Amostragem registrada com sucesso!',
    ERRO = 'Erro ao registrar a amostragem.',
}

export enum NotificacaoAtualizacaoAmostragemEnum {
    SUCESSO = 'Amostragem atualizada com sucesso!',
    ERRO = 'Erro ao atualizar a amostragem.',
    ALERTA = 'Informe todos os campos necessários',
}

export enum NotificacaoExclusaoAmostragemEnum {
    SUCESSO = 'Amostragem excluída com sucesso!',
}
