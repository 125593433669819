import { Injectable } from '@angular/core';
import { PendulosSiloStatusConfiguracaoEnum } from 'app/shared/enums';
import { configuracaoPendulosSilo } from 'app/shared/constants';

export abstract class IDesenhaPeundulosVisaoSuperiorService {
    abstract execute(pendulos: any[], comunicacaoPendulos: string): any;
}

@Injectable({ providedIn: 'root' })
export class DesenhaPeundulosVisaoSuperiorService implements IDesenhaPeundulosVisaoSuperiorService {
    execute(pendulos: any[], comunicacaoPendulos: string): any {
        const chapeu = document.getElementById('container-chapeu-silo');
        let numeroPendulosConfigurados = 0;
        pendulos.forEach((pendulo, index) => {
            if (this.penduloDesconfigurado(pendulo)) return;

            chapeu.innerHTML += `<span class="h-7 w-7 rounded-full justify-center items-center flex absolute m-0"
                style="left: ${pendulo.pendulo_propriedade.posicao_x}px;
                top: ${pendulo.pendulo_propriedade.posicao_y}px;
                background: ${this.configuraPendulo(index, comunicacaoPendulos).background};
                color: ${this.configuraPendulo(index, comunicacaoPendulos).texto}"
            >${pendulo.codigo}</span>`;

            numeroPendulosConfigurados++;
        });
        return {
            status: this.pendulosSiloStatusConfiguracao(pendulos, numeroPendulosConfigurados),
            pendulosDesconfigurados: pendulos.length - numeroPendulosConfigurados,
        };
    }

    private configuraPendulo(indexPendulo: number, comunicacaoPendulos: string): any {
        const comunicacaoPendulosArray = comunicacaoPendulos.split('|');

        let configuraPendulo = {
            background: configuracaoPendulosSilo.comunicando.background,
            texto: configuracaoPendulosSilo.comunicando.texto,
        };

        if (comunicacaoPendulosArray[indexPendulo] === '0') {
            configuraPendulo.background = configuracaoPendulosSilo.semComunicacao.background;
            configuraPendulo.texto = configuracaoPendulosSilo.semComunicacao.texto;
        }
        return configuraPendulo;
    }

    private penduloDesconfigurado(pendulo): boolean | false {
        if (
            !pendulo.pendulo_propriedade.posicao_x ||
            !pendulo.pendulo_propriedade.posicao_y ||
            pendulo.pendulo_propriedade.posicao_x === null ||
            pendulo.pendulo_propriedade.posicao_y === null
        ) {
            return true;
        }
    }

    private pendulosSiloStatusConfiguracao(pendulos: any[], contador: number): PendulosSiloStatusConfiguracaoEnum {
        switch (true) {
            case contador === 0: {
                return PendulosSiloStatusConfiguracaoEnum.NAO_CONFIGURADO;
            }
            case contador < pendulos.length: {
                return PendulosSiloStatusConfiguracaoEnum.PARCIALMENTE_CONFIGURADO;
            }
            case contador === pendulos.length: {
                return PendulosSiloStatusConfiguracaoEnum.CONFIGURADO;
            }
        }
    }
}
