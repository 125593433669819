import { Injectable } from '@angular/core';

import moment from 'moment';

import { ConfiguraDayParaDataService } from 'app/shared/services';

export abstract class IRemoveTimezoneData {
    abstract execute(momento: any): string;
}
@Injectable({ providedIn: 'root' })
export class RemoveTimezoneData implements IRemoveTimezoneData {
    constructor(readonly configuraDayParaDataService: ConfiguraDayParaDataService) {}
    execute(momento: any): string {
        const data = this.configuraDayParaDataService.execute(moment(momento).utc(true));
        return data.replace('Z', '');
    }
}
