import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { VisaoPlanaSiloComponent } from 'app/shared/components/visao-plana-silo/visao-plana-silo.component';
import { SiloHistoricoInterface } from 'app/modules/armazenagem/visualizacao/silos/interfaces';
import { IControleAcoesHistoricoService } from 'app/shared/services';

@Component({
    selector: 'app-controle-acoes-historico',
    templateUrl: './controle-acoes-historico.component.html',
    styleUrls: ['./controle-acoes-historico.component.scss'],
})
export class ControleAcoesHistoricoComponent implements OnInit {
    @Input() historicoArray?: SiloHistoricoInterface[];
    @Input() visao?: VisaoPlanaSiloComponent;
    @Output() acaoPlay = new EventEmitter<any>();
    @Output() historicoSelecionado = new EventEmitter<SiloHistoricoInterface>();
    historicoAtual: SiloHistoricoInterface;
    controleHistoricoSilo: FormControl = new FormControl();
    controleIndexHistorico: FormControl = new FormControl();
    playStartado: boolean = false;
    permiteRotarPlay: boolean = false;

    constructor(private controleAcoesHistoricoService: IControleAcoesHistoricoService) {}

    ngOnInit(): void {
        this.setarHistoricoInicial();
    }

    avancarProximoHistoricoSilo(): void {
        this.permiteRotarPlay = this.controleAcoesHistoricoService.desabilitaBotaoPlay(
            this.historicoAtual.indexLeitura,
            this.historicoArray
        );
        this.historicoAtual = this.controleAcoesHistoricoService.avancarProximaPosicao(
            this.historicoAtual.indexLeitura,
            this.historicoArray
        );
        this.historicoSelecionado.emit(this.historicoAtual);
    }

    avancarUltimoHistoricoSilo(): void {
        this.permiteRotarPlay = this.controleAcoesHistoricoService.desabilitaBotaoPlay(
            this.historicoAtual.indexLeitura,
            this.historicoArray
        );
        this.historicoAtual = this.controleAcoesHistoricoService.avancarUltimaPosicao(
            this.historicoAtual.indexLeitura,
            this.historicoArray
        );
        this.historicoSelecionado.emit(this.historicoAtual);
    }

    voltarHistoricoSiloanterior(): void {
        this.permiteRotarPlay = this.controleAcoesHistoricoService.desabilitaBotaoPlay(
            this.historicoAtual.indexLeitura,
            this.historicoArray
        );
        this.historicoAtual = this.controleAcoesHistoricoService.voltarPosicaoAnterior(
            this.historicoAtual.indexLeitura,
            this.historicoArray
        );
        this.historicoSelecionado.emit(this.historicoAtual);
    }

    async playHistorico(): Promise<void> {
        this.playStartado = !this.playStartado;
        const posicaoAtual = this.controleAcoesHistoricoService.retornaIndex(
            this.historicoAtual.indexLeitura,
            this.historicoArray
        );
        for (let i = posicaoAtual; i <= this.historicoArray.length; i++) {
            this.acaoPlay.emit({ playStartado: this.playStartado });
            if (i === this.historicoArray.length) {
                this.playStartado = false;
                this.permiteRotarPlay = false;
                this.acaoPlay.emit({ playStartado: this.playStartado });
            }
            if (!this.playStartado) {
                break;
            }
            this.historicoAtual = this.historicoArray[i];
            this.historicoSelecionado.emit(this.historicoAtual);
            if (i === 0 || !this.playStartado) {
                await this.temporizador(0);
            } else {
                await this.temporizador(500);
            }
        }
    }

    temporizador(ms = 0): Promise<boolean> {
        return new Promise((r) => setTimeout(r, ms));
    }

    voltarPrimeiroHistoricoSilo(): void {
        this.permiteRotarPlay = this.controleAcoesHistoricoService.desabilitaBotaoPlay(
            this.historicoAtual.indexLeitura,
            this.historicoArray
        );
        this.historicoAtual = this.controleAcoesHistoricoService.voltarPrimeiraPosicao(
            this.historicoAtual.indexLeitura,
            this.historicoArray
        );
        this.historicoSelecionado.emit(this.historicoAtual);
    }

    desabilitaBotoesProximos(): boolean {
        return this.controleAcoesHistoricoService.desabilitaBotoesProximos(
            this.historicoAtual.indexLeitura,
            this.historicoArray
        );
    }

    desabilitaBotoesAnteriores(): boolean {
        return this.controleAcoesHistoricoService.desabilitaBotoesAnteriores(
            this.historicoAtual.indexLeitura,
            this.historicoArray
        );
    }

    setarHistoricoInicial(): void {
        if (this.historicoArray?.length) {
            this.permiteRotarPlay = false;
            this.historicoAtual = this.historicoArray[this.historicoArray.length - 1];
            this.historicoSelecionado.emit(this.historicoAtual);
        }
    }
}
