import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';


export abstract class IObjectToAnyService {
  abstract toQueryString(object: any): HttpParams;
}

@Injectable({ providedIn: 'root' })
export class ObjectToAnyService implements IObjectToAnyService {

  public toQueryString(object: any): HttpParams {
    let paramsHttp = new HttpParams();

    Object.keys(object).map(key => {
      paramsHttp = paramsHttp.set(key, object[key]);
    });

    return paramsHttp;
  }
}
