import { TipoDeAlerta } from 'app/shared/models';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export abstract class IAlertaService {
    abstract mostrarSucesso(titulo: string): void;
    abstract mostrarErro(titulo: string, mensagem: string): void;
    abstract mostrarAlerta(titulo: string, mensagem: string): void;
    abstract get alerta$(): Observable<TipoDeAlerta>;
}
@Injectable({
    providedIn: 'root',
})
export class AlertaService implements IAlertaService {
    private alerta: BehaviorSubject<TipoDeAlerta | null> = new BehaviorSubject(null);

    constructor() {}

    get alerta$(): Observable<TipoDeAlerta> {
        return this.alerta.asObservable();
    }

    mostrarSucesso(titulo: string): void {
        this.mostrar(
            new TipoDeAlerta({
                titulo,
            })
        );
    }

    mostrarErro(titulo: string, mensagem: string): void {
        this.mostrar(
            new TipoDeAlerta({
                tipo: 'error',
                tempo: 5000,
                titulo,
                mensagem,
            })
        );
    }

    mostrarAlerta(titulo: string, mensagem: string): void {
        this.mostrar(
            new TipoDeAlerta({
                tipo: 'warning',
                tempo: 5000,
                titulo,
                mensagem,
            })
        );
    }

    private mostrar(tipoDeAlerta: TipoDeAlerta): void {
        tipoDeAlerta.mostrar = true;
        this.alerta.next(tipoDeAlerta);
        setTimeout(() => {
            tipoDeAlerta.mostrar = false;
            this.alerta.next(tipoDeAlerta);
        }, tipoDeAlerta.tempo);
    }
}
