import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { CookieService } from 'app/core/services/cookies/cookie.service';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { EstadoNavegacaoUnidadesEnum } from 'app/shared/enums';
import { FilialModel, GrupoFiliaisModel } from './models';
import {
    IEstacaoMeteorologicaLocalController,
    IUnidadeParametrizacaoController,
    IUsuarioController,
} from 'app/shared/controllers';
import { UnidadeParametrizacaoObservable } from 'app/shared/observables';

@Component({
    selector: 'app-header-select-customer',
    templateUrl: './header-select-customer.component.html',
    styleUrls: ['./header-select-customer.component.scss'],
})
export class HeaderSelectCustomerComponent implements OnInit, OnDestroy {
    @ViewChild('singleSelect') singleSelect: MatSelect;
    @Output() trocaUnidade = new EventEmitter();

    filialCtrl: FormControl = new FormControl();
    filialFiltroCtrl: FormControl = new FormControl();
    avancarFilialBtn: boolean;
    avancarUltimaFilialBtn: boolean;
    voltarFilialBtn: boolean;
    voltarPrimeiraFilialBtn: boolean;
    filiaisFiltradas: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filiais: GrupoFiliaisModel[] = [];
    idFilialSelecionada = 0;
    buscandoFilial = false;
    selectAberto: boolean = false;
    protected onDestroy = new Subject<void>();

    constructor(
        private cookieSerice: CookieService,
        private estacaoMeteorologicaLocalController: IEstacaoMeteorologicaLocalController,
        private unidadeParametrizacaoController: IUnidadeParametrizacaoController,
        private unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable,
        private usuarioController: IUsuarioController
    ) {}

    ngOnInit(): void {
        this.buscaUsuarioLocal();
        if (localStorage.getItem('unidades')) {
            const unidades = JSON.parse(localStorage.getItem('unidades'));
            unidades.forEach((unit, index) => {
                unit.unidades.forEach((item: any) => {
                    item.indexGroup = index;
                });
            });
            this.filiais = unidades;
            this.setarValorInicial();
        }
        this.filialFiltroCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.filtrarFiliais();
        });
    }

    hideToggleUnit(value: boolean): void {
        this.selectAberto = value;
        this.buscandoFilial = value;
    }

    setarValorInicial(): void {
        if (
            JSON.parse(localStorage.getItem('unidadeAtual')) === null ||
            JSON.parse(localStorage.getItem('unidadeAtual')) === undefined
        ) {
            this.filialCtrl.setValue(this.filiais[0].unidades[0]);
            localStorage.setItem('unidadeAtual', JSON.stringify(this.filiais[0].unidades[0]));
        } else {
            const unidadeAtual = JSON.parse(localStorage.getItem('unidadeAtual'));
            const indexUnidadeAtual = this.filiais[unidadeAtual.indexGroup].unidades.findIndex(
                (unidade) => unidade.id === unidadeAtual.id
            );
            this.filialCtrl.setValue(this.filiais[unidadeAtual.indexGroup].unidades[indexUnidadeAtual ?? 0]);
        }

        this.filiaisFiltradas.next(this.filiais.slice());
        const unidadeAtual = JSON.parse(localStorage.getItem('unidadeAtual'));
        this.configurarSetasNavegacao(unidadeAtual.indexGroup, this.filiais.length);
    }

    opcaoSelecionadaNoSelect(event): void {
        localStorage.setItem('unidadeAtual', JSON.stringify(event.value));
        this.idFilialSelecionada = event.value.id;
        this.atualizaUnidadeId(this.idFilialSelecionada);
        this.configurarSetasNavegacao(event.value.indexGroup, this.filiais.length);
    }

    mostrarClienteSelecionado(grupo: GrupoFiliaisModel, unidade: FilialModel): string {
        if (this.selectAberto) {
            return unidade.nome !== null && unidade.nome !== undefined
                ? '(' + unidade.nome + ')' + ' ' + unidade.cidade + ' - ' + unidade.estado
                : unidade.cidade + ' - ' + unidade.estado;
        }
        return unidade.nome !== null && unidade.nome !== undefined
            ? grupo.nomeCliente + ': ' + '(' + unidade.nome + ')' + ' ' + unidade.cidade + ' - ' + unidade.estado
            : grupo.nomeCliente + ': ' + unidade.cidade + ' - ' + unidade.estado;
    }

    verificarSelectAberto(event: boolean): void {
        this.selectAberto = event;
    }

    retornaIndexFilial(id: number): number {
        let index = null;
        this.filiais.forEach((filial: any) => {
            filial.unidades.forEach((element, posicao) => {
                if (id === element.id) {
                    index = posicao;
                }
            });
        });
        return index;
    }

    voltarFilial(): void {
        let posicaoAtualGrupo = this.filialCtrl.value.indexGroup;
        const lengthUnidades = this.filiais[posicaoAtualGrupo].unidades.length - 1;
        const posicaoAtual = this.retornaIndexFilial(this.filialCtrl.value.id);

        if (posicaoAtual > 0) {
            const filialAnterior = this.filiais[posicaoAtualGrupo].unidades[posicaoAtual - 1];
            this.filialCtrl.setValue(filialAnterior);
            this.atualizaUnidadeId(filialAnterior.id);
            localStorage.setItem('unidadeAtual', JSON.stringify(filialAnterior));
        } else {
            posicaoAtualGrupo = posicaoAtualGrupo - 1;
            if (posicaoAtualGrupo < 0) {
                posicaoAtualGrupo = 0;
            }
            const filialAnterior = this.filiais[posicaoAtualGrupo]?.unidades[lengthUnidades];
            this.filialCtrl.setValue(filialAnterior);
            this.atualizaUnidadeId(filialAnterior.id);
            localStorage.setItem('unidadeAtual', JSON.stringify(filialAnterior));
        }
        this.configurarSetasNavegacao(posicaoAtualGrupo, this.filiais.length, EstadoNavegacaoUnidadesEnum.ANTERIOR);
    }

    voltarPrimeiraFilial(): void {
        const primeiraFilial = this.filiais[0].unidades[0];
        this.filialCtrl.setValue(primeiraFilial);
        localStorage.setItem('unidadeAtual', JSON.stringify(primeiraFilial));
        this.atualizaUnidadeId(primeiraFilial.id);
        this.configurarSetasNavegacao(0, 0, EstadoNavegacaoUnidadesEnum.PRIMEIRO);
    }

    avancarFilial(): void {
        let posicaoAtualGrupo = this.filialCtrl.value.indexGroup;
        const lengthUnidades = this.filiais[posicaoAtualGrupo].unidades.length - 1;
        const posicaoAtual = this.retornaIndexFilial(this.filialCtrl.value.id);

        if (posicaoAtual < lengthUnidades) {
            let proximaFilial = this.filiais[posicaoAtualGrupo]?.unidades[posicaoAtual + 1];
            this.filialCtrl.setValue(proximaFilial);
            this.atualizaUnidadeId(proximaFilial.id);
            localStorage.setItem('unidadeAtual', JSON.stringify(proximaFilial));
        } else {
            posicaoAtualGrupo = posicaoAtualGrupo + 1;
            if (posicaoAtualGrupo > this.filiais.length - 1) {
                posicaoAtualGrupo = this.filiais.length - 1;
            }
            const proximaFilial = this.filiais[posicaoAtualGrupo]?.unidades[0];
            this.filialCtrl.setValue(proximaFilial);
            this.atualizaUnidadeId(proximaFilial?.id);
            localStorage.setItem('unidadeAtual', JSON.stringify(proximaFilial));
        }
        this.configurarSetasNavegacao(posicaoAtualGrupo, this.filiais.length, EstadoNavegacaoUnidadesEnum.PROXIMO);
    }

    avancarUltimaFilial(): void {
        const posicaoUltimoGrupo = this.filiais.length - 1;
        const posicaoUltimaFilial = this.filiais[posicaoUltimoGrupo].unidades.length - 1;
        const ultimaFilial = this.filiais[posicaoUltimoGrupo].unidades[posicaoUltimaFilial];
        this.filialCtrl.setValue(ultimaFilial);
        localStorage.setItem('unidadeAtual', JSON.stringify(ultimaFilial));
        this.atualizaUnidadeId(ultimaFilial.id);
        this.configurarSetasNavegacao(0, 0, EstadoNavegacaoUnidadesEnum.ULTIMO);
    }

    atualizaUnidadeId(id: any): void {
        this.trocaUnidade.emit(true);
        this.cookieSerice.definirCookie('unidadeId', id, 0);
        this.buscaUsuarioLocal();
        this.atualizaInformacoesGlobaisDaUnidade();
    }

    atualizaInformacoesGlobaisDaUnidade(): void {
        this.estacaoMeteorologicaLocalController.buscaUmaEstacaoMeteorologica().pipe(take(1)).subscribe();
        this.unidadeParametrizacaoController
            .buscaParametrosUnidade()
            .pipe(take(1))
            .subscribe((response) => {
                this.unidadeParametrizacaoObservable.setParametrosUnidade = response;
            });
    }

    configurarSetasNavegacao(indexGroup: number, length: number, estado?: string) {
        let primeiroGrupo = indexGroup === 0;
        let ultimoGrupo = indexGroup === length - 1;

        if (estado === undefined) {
            this.voltarFilialBtn = this.voltarPrimeiraFilialBtn = primeiroGrupo;
            this.avancarFilialBtn = this.avancarUltimaFilialBtn = ultimoGrupo;
        } else if (estado === EstadoNavegacaoUnidadesEnum.PRIMEIRO) {
            this.voltarFilialBtn = this.voltarPrimeiraFilialBtn = true;
            this.avancarFilialBtn = this.avancarUltimaFilialBtn = false;
        } else if (estado === EstadoNavegacaoUnidadesEnum.ULTIMO) {
            this.voltarFilialBtn = this.voltarPrimeiraFilialBtn = false;
            this.avancarFilialBtn = this.avancarUltimaFilialBtn = true;
        } else if (estado === EstadoNavegacaoUnidadesEnum.PROXIMO || estado === EstadoNavegacaoUnidadesEnum.ANTERIOR) {
            this.voltarFilialBtn = this.voltarPrimeiraFilialBtn = primeiroGrupo;
            this.avancarFilialBtn = this.avancarUltimaFilialBtn = ultimoGrupo;
        }
    }

    protected filtrarFiliais(): void {
        if (!this.filiais) {
            return;
        }
        let buscar = this.filialFiltroCtrl.value;
        if (!buscar) {
            this.filiaisFiltradas.next(this.filiais.slice());
            return;
        } else {
            buscar = buscar.toLowerCase();
        }
        const grupoFiliaisCopia = this.copiarGrupoUnidades(this.filiais);
        this.filiaisFiltradas.next(
            grupoFiliaisCopia.filter((filial: any) => {
                const mostrarGrupoUnidades = filial.nomeCliente.toLowerCase().indexOf(buscar) > -1;
                if (!mostrarGrupoUnidades) {
                    filial.unidades = filial.unidades.filter(
                        (unity: any) => unity.nome.toLowerCase().indexOf(buscar) > -1
                    );
                }
                return filial.unidades.length > 0;
            })
        );
    }

    private copiarGrupoUnidades(filialGrupos: any[]): any {
        const filialGruposCopia = [] as any[];
        filialGrupos.forEach((filialGrupo) => {
            filialGruposCopia.push({
                nomeCliente: filialGrupo.nomeCliente,
                unidades: filialGrupo.unidades.slice(),
            });
        });
        return filialGruposCopia;
    }

    private buscaUsuarioLocal(): void {
        const usuarioGlobal = JSON.parse(localStorage.getItem('usuarioLogadoGlobal'));
        let usuarioLogado = JSON.parse(localStorage.getItem('usuarioLogado'));
        if (!usuarioGlobal) return;
        this.usuarioController
            .buscaUsuarioGlobal(usuarioGlobal)
            .pipe(takeUntil(this.onDestroy))
            .subscribe((usuario) => {
                usuarioLogado.id = usuario.id;
                localStorage.setItem('usuarioLogado', JSON.stringify(usuarioLogado));
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }
}
