import { Injectable } from "@angular/core";
import { AsideMenuObservable } from "app/shared/observables";

@Injectable({
    providedIn: 'root',
})
export class ClassicService {
    constructor(private asideMenuObservable: AsideMenuObservable) {}

    abrirMenuSobreposto() {
        document.getElementById("sobreposicao").style.display = "block";
    }

    fecharMenuSobreposto() {
        document.getElementById("sobreposicao").style.display = "none";
        this.asideMenuObservable.menuMinimizadoSubject.next(true);
    }
}
