import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';

import { UnidadeInterface, UnidadeProdutoInterface } from 'app/shared/interfaces';
import { UnidadeParametrizacaoObservable } from 'app/shared/observables';
import { ProdutosProcerEnum } from 'app/shared/enums';

export abstract class IUnidadeProdutosObservable {
    abstract contemProdutoAeracao(): Observable<boolean>;
}

@Injectable({
    providedIn: 'root',
})
export class UnidadeProdutosObservable implements IUnidadeProdutosObservable {
    unidade: UnidadeInterface;

    constructor(private unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable) {}

    contemProdutoAeracao(): Observable<boolean> {
        return this.unidadeParametrizacaoObservable.buscaParametrosUnidade$.pipe(
            map((unidade: UnidadeInterface) => {
                return this.verificarProdutoAeracao(unidade);
            })
        );
    }

    private verificarProdutoAeracao(unidade: UnidadeInterface): boolean {
        return unidade.unidade_produtos.some(
            (e: UnidadeProdutoInterface) => e.produto === ProdutosProcerEnum.AERACAO_AUTOMATICA
        );
    }
}
