import { Injectable } from '@angular/core';
import { DiasSemanaEnum } from 'app/modules/armazenagem/cadastro/regras/cadastro/enums';
export abstract class IRetornaDiasSemanaEnumService {
    abstract execute(formula: string): string;
}

@Injectable()
export class RetornaDiasSemanaEnumService implements IRetornaDiasSemanaEnumService {
    public execute(formula: string): string {
        if (formula.includes(DiasSemanaEnum.SEGUNDA_FEIRA)) return DiasSemanaEnum.SEGUNDA_FEIRA;
        if (formula.includes(DiasSemanaEnum.TERCA_FEIRA)) return DiasSemanaEnum.TERCA_FEIRA;
        if (formula.includes(DiasSemanaEnum.QUARTA_FEIRA)) return DiasSemanaEnum.QUARTA_FEIRA;
        if (formula.includes(DiasSemanaEnum.QUINTA_FEIRA)) return DiasSemanaEnum.QUINTA_FEIRA;
        if (formula.includes(DiasSemanaEnum.SEXTA_FEIRA)) return DiasSemanaEnum.SEXTA_FEIRA;
        if (formula.includes(DiasSemanaEnum.SABADO)) return DiasSemanaEnum.SABADO;
        if (formula.includes(DiasSemanaEnum.DOMINGO)) return DiasSemanaEnum.DOMINGO;
    }
}
