<div class="selctUnit w-full flex flex-row items-center" *ngIf="filiais.length > 0">
    <div class="sm:flex hidden" *ngIf="!buscandoFilial">
        <button
            mat-icon-button
            (click)="voltarPrimeiraFilial()"
            [disabled]="voltarPrimeiraFilialBtn"
            matTooltip="Clique para ver a primeira unidade"
            matTooltipClass="tooltip-padrao first-unity"
        >
            <mat-icon svgIcon="heroicons_outline:chevron-double-left" class="icon-size-5 tooltip"> </mat-icon>
        </button>
        <button
            mat-icon-button
            (click)="voltarFilial()"
            matTooltip="Clique para ver a unidade anterior"
            matTooltipClass="tooltip-padrao previous-unity"
            [disabled]="voltarFilialBtn"
        >
            <mat-icon svgIcon="heroicons_outline:chevron-left" class="icon-size-5"> </mat-icon>
        </button>
    </div>
    <div class="flex">
        <mat-form-field class="w-[138px] sm:w-full mt-4" [floatLabel]="'always'" (click)="hideToggleUnit(true)">
            <mat-select
                [formControl]="filialCtrl"
                placeholder="Procurar cliente ou unidade..."
                #singleSelect
                (selectionChange)="opcaoSelecionadaNoSelect($event)"
                (openedChange)="verificarSelectAberto($event)"
                (closed)="hideToggleUnit(false)"
                class="sm:bg-card bg-[#00091F] text-[#BAC3D2]"
                panelClass = "containerSelectUnidade"
            >
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="filialFiltroCtrl"
                        placeholderLabel="Procurar cliente ou unidade..."
                        noEntriesFoundLabel="Nenhuma resultado encontrado..."
                    >
                    </ngx-mat-select-search>
                </mat-option>
                <mat-optgroup
                    *ngFor="let group of filiaisFiltradas | async; let i = index"
                    [attr.data-index]="i"
                    [label]="group.nomeCliente"
                >
                    <mat-option *ngFor="let unity of group.unidades" [value]="unity">
                        {{ mostrarClienteSelecionado(group, unity) }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
            <mat-icon class="block sm:hidden cursor-pointer text-[#bac3d2]">
                arrow_drop_down
            </mat-icon>
        </mat-form-field>
    </div>
    <div class="sm:flex hidden" *ngIf="!buscandoFilial">
        <button
            mat-icon-button
            (click)="avancarFilial()"
            matTooltip="Clique para ver a próxima unidade"
            matTooltipClass="tooltip-padrao next-unity"
            [disabled]="avancarFilialBtn"
        >
            <mat-icon svgIcon="heroicons_outline:chevron-right" class="icon-size-5 tooltip"> </mat-icon>
        </button>
        <button
            mat-icon-button
            (click)="avancarUltimaFilial()"
            matTooltip="Clique para ver a última unidade"
            matTooltipClass="tooltip-padrao last-unity"
            [disabled]="avancarUltimaFilialBtn"
        >
            <mat-icon svgIcon="heroicons_outline:chevron-double-right" class="icon-size-5"> </mat-icon>
        </button>
    </div>
</div>
