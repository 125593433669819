import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Observable, of, switchMap } from 'rxjs';
import { IApiTransportInterface } from '../interfaces';
import { CredenciaisLoginInterface, LoginResponseInterface } from '../interfaces/auth.interface';
import { CookieService } from '../services/cookies/cookie.service';
import { ApiTransportService } from '../services/transport';

export abstract class IAuthService {
    abstract sign(credenciais: CredenciaisLoginInterface): Observable<LoginResponseInterface>;
    abstract signOut(): Observable<boolean>;
    abstract signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>;
    abstract unlockSession(credentials: { email: string; password: string }): Observable<any>;
    abstract forgotPassword(email: string): Observable<any>;
    abstract resetPassword(password: string): Observable<any>;
    abstract check(): Observable<boolean>;
}
@Injectable()
export class AuthService implements IAuthService {
    private transport: IApiTransportInterface;
    private authenticated: boolean;

    constructor(
        private http: HttpClient,
        public readonly apiTransportService: ApiTransportService,
        private cookieSerice: CookieService
    ) {
        this.transport = this.apiTransportService.execute('/auth');
    }

    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    sign(credenciais: CredenciaisLoginInterface): Observable<LoginResponseInterface> {
        localStorage.setItem('emailUsuarioLogado', credenciais.email);
        return this.transport.http.post<LoginResponseInterface>(`${this.transport.path}/login/`, credenciais).pipe(
            switchMap((response: LoginResponseInterface) => {
                this.accessToken = response.accessToken;
                this.authenticated = true;
                this.guardarDadosUsuarioLogado(response);
                return of(response);
            })
        );
    }

    signOut(): Observable<any> {
        this.cookieSerice.apagarCookie('unidadeId');
        this.authenticated = false;
        this.limparLocalStorage();
        return of(true);
    }

    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this.http.post('api/auth/sign-up', user);
    }

    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this.http.post('api/auth/unlock-session', credentials);
    }

    forgotPassword(email: string): Observable<any> {
        return this.http.post('api/auth/forgot-password', email);
    }

    resetPassword(password: string): Observable<any> {
        return this.http.post('api/auth/reset-password', password);
    }

    check(): Observable<boolean> {
        if (this.authenticated) {
            return of(true);
        }

        if (!this.accessToken) {
            return of(false);
        }

        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }
        return of(true);
    }

    private limparLocalStorage(): void {
        const whiteLabelCliente = localStorage.getItem('whiteLabelCliente');
        localStorage.clear();
        if (whiteLabelCliente) {
            localStorage.setItem('whiteLabelCliente', whiteLabelCliente);
        }
    }

    private guardarDadosUsuarioLogado(response: LoginResponseInterface): void {
        localStorage.setItem('unidades', JSON.stringify(response.unidades));
        localStorage.setItem('usuarioLogado', JSON.stringify(response.usuario));
        localStorage.setItem('usuarioLogadoGlobal', JSON.stringify(response.usuario_global_ref));
        this.cookieSerice.definirCookie('unidadeId', response.unidadeId, 0);
    }
}
