import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

import {
    ISincronizadorController,
    SincronizadorController,
    SincronizadorEquipamentoComponent,
} from 'app/layout/common/sincronizador-equipamento';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    declarations: [SincronizadorEquipamentoComponent],
    imports: [CommonModule, MatTooltipModule, MatIconModule, TranslocoRootModule],
    exports: [SincronizadorEquipamentoComponent],
    providers: [{ provide: ISincronizadorController, useClass: SincronizadorController }],
})
export class SincronizadorEquipamentoModule {}
