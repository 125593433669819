import { EstruturaAmostragemLeituraTipoEnum, EstruturaAmostragemLeituraDescargaEnum } from 'app/shared/enums';

export class AmostragemLeituraModel {
    estrutura_amostragem_id: number;
    estrutura_amostragem_leitura_tipo: EstruturaAmostragemLeituraTipoEnum;
    estrutura_amostragem_descarga_posicao: EstruturaAmostragemLeituraDescargaEnum;
    pendulo_ref: number;
    umidade: number;
    impureza: number;
    avariados: number;

    constructor(partial?: Partial<AmostragemLeituraModel>) {
        Object.assign(this, partial);
    }
}
