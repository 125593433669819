export const graficoTraducao = {
    name: 'pt-br',
    options: {
        months: [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
        ],
        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        toolbar: {
            download: 'Baixar SVG',
            selection: 'Selecionar',
            selectionZoom: 'Selecionar Zoom',
            zoomIn: 'Aumentar',
            zoomOut: 'Diminur',
            pan: 'Navegação',
            reset: 'Reiniciar Zoom',
        },
    },
};
