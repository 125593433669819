import { EstadoComunicacaoEnum } from 'app/shared/enums';
import { Injectable } from '@angular/core';

export abstract class IRetornaEstiloStatusComunicacao {
    abstract statusComunicacaoSilo(estado: string): string;
}
@Injectable({ providedIn: 'root' })
export class RetornaEstiloStatusComunicacao implements IRetornaEstiloStatusComunicacao {
    statusComunicacaoSilo(estado: string): string {
        const statusCommunicationColor = {
            GREEN: '#01FF70',
            RED: '#D65757E5',
            YELLOW: '#d4d411',
        };

        let status: any = {};

        if (estado === EstadoComunicacaoEnum.ALL) {
            status.segundaLinhaTooltip = 'Comunicando';
            status.classTooltip = 'tooltip-em-execucao';
            status.cor = statusCommunicationColor.GREEN;
        }

        if (estado === EstadoComunicacaoEnum.NONE) {
            status.segundaLinhaTooltip = 'Falha de comunicação';
            status.classTooltip = 'tooltip-sem-comunicacao';
            status.cor = statusCommunicationColor.RED;
        }

        if (estado === EstadoComunicacaoEnum.PARTIAL) {
            status.segundaLinhaTooltip = 'Comunicação parcial';
            status.classTooltip = 'tooltip-comunicacao-parcial';
            status.cor = statusCommunicationColor.YELLOW;
        }

        return status;
    }
}
