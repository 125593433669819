import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { whiteLabelService } from 'app/core/services/white-label';

@NgModule()
export class IconsModule {
    get whiteLabelCliente(): string {
        this.whiteLabelService.obterCliente();
        return localStorage.getItem('whiteLabelCliente');
    }

    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry,
        private whiteLabelService: whiteLabelService
    ) {
        this._matIconRegistry.addSvgIconSet(
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'iconsmind',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'feather',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg')
        );
        //Icons Menus
        this._matIconRegistry.addSvgIcon(
            'icon-module-armazenagem',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/modulo_armazenagem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-module-secador',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-secagem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-module-gestao-unidade',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/modulo_gestao_unidade.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-module-configuracoes',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_configuracoes.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grupo-visualizacao',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/menus/grupo_visualizacao_kepler.svg'
                    : 'assets/icons/menus/grupo_visualizacao.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-painel-geral',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_painel_geral.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-planta-unidade',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_planta_unidade.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-silos',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente
                    ? 'assets/icons/menus/menu-silos-white-label.svg'
                    : 'assets/icons/menus/menu-silos-ceres.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-armazens',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_armazens.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grupo-analise',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/menus/icon-grupo-analise-kepler.svg'
                    : 'assets/icons/menus/grupo_analise.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-relatorios',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_relatorios.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-leitura-sensores',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-leitura-sensores.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-sensores',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-sensores-laranja.svg'
                    : 'assets/icons/menus/silo/sensores-icone.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-fabricante',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-fabricante-laranja.svg'
                    : 'assets/icons/menus/silo/sensores-icone.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-aeracao-tempo',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente
                    ? 'assets/icons/menus/menu-aeracao-tempo-white-label.svg'
                    : 'assets/icons/menus/menu-aeracao-tempo-ceres.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-sensor-plenum',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-sensor-plenum.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-aeracao-programa',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-aeracao-programa.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-aeracao-equilibrio',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-aeracao-equilibrio.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-umidade-grao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-umidade-grao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-leitura-auxiliar',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-leitura-auxiliar.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-leitura-co2',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-leitura-co2.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-grao-secagem',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-grao-secagem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-pluviometro-estacao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-pluviometro-estacao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-graficos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-graficos.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-eventos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/icon-menu-eventos.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-estoque',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-estoque.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-pluviometria',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-pluviometria.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-armazenagem-preditiva',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-armazenagem-preditiva.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grupo-cadastro',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/menus/grupo_cadastro_kepler.svg'
                    : 'assets/icons/menus/grupo_cadastro.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-amostragens',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente
                    ? 'assets/icons/menus/menu-amostragens-white-label.svg'
                    : 'assets/icons/menus/menu-amostragens-ceres.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grupo-monitoramento',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/grupo_monitoramento.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-analisadores',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-analisadores.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grupo-configuracoes',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/menus/grupo_configuracoes_kepler.svg'
                    : 'assets/icons/menus/grupo_configuracoes.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-regras',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-regras.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-programas',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-programas.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-gestao-energia',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-gestao-energia.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-variaveis-aquecimento',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-variaveis-aquecimento.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-unidade-armazenadora',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente
                    ? 'assets/icons/menus/menu-unidade-armazenadora-white-label.svg'
                    : 'assets/icons/menus/menu-unidade-armazenadora-ceres.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-sensores',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_sensores.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-alertas',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-alertas.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-painel',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_painel.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-visao-geral',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_visao_geral.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-historico',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_historico.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-painel-amostragem',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_painel_amostragem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-programas',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-progrmas.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-expedicao-recebimento',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-expedicao-recebimento.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-qualidade-amostragem',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_qualidade_amostragem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-expedicao-recebimento-truck',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_expedicao_recebimento_truck.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-qualidade-amostragem',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_qualidade_amostragem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-tipos-amostras',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_tipos_amostras.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-equipamentos-industriais',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_equipamentos_industriais.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-integracao-industrial',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_integracao_industrial.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-permissoes-usuarios',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_permissoes_usuarios.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-versoes-sistema',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_versoes_sistema.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-grao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-grao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-communicating-circle',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-communicating-circle.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gota',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-gota.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gota-hover',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-gota-laranja.svg'
                    : 'assets/icons/icon-gota-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-temperatura',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-temperatura-laranja.svg'
                    : 'assets/icons/icon-temperatura-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-temperatura-painel',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-temperatura-painel-laranja.svg'
                    : 'assets/media/storage/icon-temperature.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-umidade-painel',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-umidade-painel-laranja.svg'
                    : 'assets/media/storage/icon-humidity.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-temperatura-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-temperatura-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-eaquipamentos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-eaquipamentos.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-info',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-info-laranja.svg'
                    : 'assets/icons/icon-info.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grao',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-grao-laranja.svg'
                    : './assets/icons/menus/silo/icone-grao.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grao-grande',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-grao-grande-laranja.svg'
                    : 'assets/icons/icone-grao-grande-azul.svg'
            )
        );

        this._matIconRegistry.addSvgIcon(
            'icon-controle-estoque',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-controle-estoque-laranja.svg'
                    : 'assets/icons/icon-controle-estoque.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-silo-vazio-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-silo-vazio-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-silo-cheio-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-silo-cheio-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-silo-estoque',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-silo-laranja-estoque.svg'
                    : 'assets/icons/icon-silo-azul-estoque.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-silo-historico',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-silo-historico-estoque.svg'
                    : 'assets/media/storage/silo-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-silo-controle',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-silo-controle.svg'
                    : 'assets/icons/icon-silo-controle-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-menu-estoque',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente
                    ? 'assets/icons/menus/icone-menu-estoque-whitelabel.svg'
                    : 'assets/icons/menus/icone-menu-estoque-ceres.svg'
            )
        );

        this._matIconRegistry.addSvgIcon(
            'icon-alert-amarelo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-alert-amarelo.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-editar',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-editar-laranja.svg'
                    : 'assets/icons/icon-editar.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-calendario',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-calendario.svg')
        );

        this._matIconRegistry.addSvgIcon(
            'icon-voltar-um',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-voltar-um.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-voltar-todos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-voltar-todos.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-avancar-um',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-avancar-um.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-play',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-play.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-avancar-todos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-avancar-todos.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-pausa',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-pausa.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-amostragem',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-amostragem-laranja.svg'
                    : 'assets/icons/icon-amostragem.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-lixeira',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-lixeira.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-arrow-left',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vector.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-olho',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/olho-laranja.svg'
                    : 'assets/icons/olho-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-duplicar',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-duplicar-laranja.svg'
                    : 'assets/icons/icon-duplicar.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-exportar',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-exportar-laranja.svg'
                    : 'assets/icons/icon-exportar.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-desarquivar',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-desarquivar-laranja.svg'
                    : 'assets/icons/icon-desarquivar-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-arquivar',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-arquivar-laranja.svg'
                    : 'assets/icons/icon-arquivar-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-whatsapp-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-whatsapp-laranja.svg'
                    : 'assets/icons/icone-whatsapp-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-carta-email-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-carta-email-laranja.svg'
                    : 'assets/icons/icone-carta-email-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-seta-esquerda-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-seta-esquerda-laranja.svg'
                    : 'assets/icons/icone-seta-esquerda-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-telefone-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-telefone-laranja.svg'
                    : 'assets/icons/icone-telefone-azul.svg'
            )
        );

        this._matIconRegistry.addSvgIcon(
            'icone-grafico',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-grafico.svg')
        );

        this._matIconRegistry.addSvgIcon(
            'icone-gota-cinza-habilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-gota-cinza-habilitado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-gota-cinza-desabilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-gota-cinza-desabilitado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-temperatura-cinza-desabilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-temperatura-cinza-desabilitado.svg')
        );

        this._matIconRegistry.addSvgIcon(
            'icone-equilibrio-higroscopico',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-equilibrio-higroscopico.svg')
        );

        this._matIconRegistry.addSvgIcon(
            'icone-link-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-link-laranja.svg'
                    : 'assets/icons/icone-link-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-aguardando',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-aguardando.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-sobrecarga-eletrica',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-sobrecarga-eletrica.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-horario-pico-energia',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-horario-pico-energia.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-qualidade-grao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-qualidade-grao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-aeracao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-aeracao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-aeracao-wl',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-aeracao-laranja.svg'
                    : 'assets/icons/icone-aeracao-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-aeracao-acionamento-manual',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-aeracao-acionamento-manual.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-plenum-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-plenum-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-volumetria-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-volumetria-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-volumetria-white',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-volumetria-white.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-co2-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-co2-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-link-externo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-link-externo.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-temperatura-branco',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-temperatura-branco.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-umidade-branco',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-umidade-branco.svg')
        );
    }
}
