import { environment } from 'environments/environment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { ISincronizadorController } from 'app/layout/common/sincronizador-equipamento';
import { SincronizadorModel } from 'app/shared/models';
import { SincronizadorEquipamentoHistoricoComponent } from 'app/layout/common/sincronizador-equipamento-historico';
import { SincronizadorEquipamentoHistoricoTipoEnum } from 'app/layout/common/sincronizador-equipamento-historico/enums';
import { isEmpty } from 'lodash';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'app-sincronizador-equipamento',
    templateUrl: './sincronizador-equipamento.componente.html',
    styleUrls: ['./sincronizador-equipamento.componente.scss'],
})
export class SincronizadorEquipamentoComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    dataSincronizador!: SincronizadorModel;
    tipoSincronizadorEnum = SincronizadorEquipamentoHistoricoTipoEnum;
    tipoAmbiente = environment.level;
    objetoVazio: boolean = true;

    constructor(
        public readonly dialog: MatDialog,
        private readonly sincronizadorController: ISincronizadorController,
        private breakpointObserver: BreakpointObserver
    ) {}

    ngOnInit(): void {
        this.sincronizadorController
            .buscaUltimaSincronizacao()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    this.dataSincronizador = data;
                    this.objetoVazio =
                        isEmpty(this.dataSincronizador.coletor) && isEmpty(this.dataSincronizador.local_nuvem);
                },
                error: (error) => {
                    console.log('error: ', error);
                },
            });
    }

    mostrarHistorico(tipo: SincronizadorEquipamentoHistoricoTipoEnum): void {
        const smallScreen = this.breakpointObserver.isMatched('(max-width: 600px)');
        const minWidth = smallScreen ? '100%' : '650px';
        const minHeight = smallScreen ? 'auto' : '416px';
        this.dialog.open(SincronizadorEquipamentoHistoricoComponent, {
            minWidth,
            minHeight,
            data: {
                tipo,
            },
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }
}
