import { TipoRegistro, TipoSensores } from 'app/shared/enums';

export class SiloRelatorioModel {
    siloId: number;
    nome?: string;
    dataInicio: string;
    dataFim: string;
    tipoRegistro: TipoRegistro;
    tipoSensor: TipoSensores;
    tempo: string;
}
