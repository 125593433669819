export class RegraResponseModel {
    arquivado: boolean;
    expressao_ligar: string;
    expressao_manter_ligado: string;
    id: number;
    nome: string;
    notas: string;
    ultima_alteracao: string;
    usuario_ref?: number;

    constructor(partial?: Partial<RegraResponseModel>) {
        Object.assign(this, partial);
    }
}
