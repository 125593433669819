import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { EditaInformacoesAeracaoPayloadInterface, ListaRecursosInterface } from 'app/shared/interfaces';
import { SiloModel, SiloComVolumeModel } from 'app/shared/models';
import { IObjectToAnyService } from 'app/shared/services';

export abstract class ISilosController {
    abstract buscaVarios(order?: string): Observable<ListaRecursosInterface<SiloModel>>;
    abstract buscaVariosComAeradores(order?: string): Observable<ListaRecursosInterface<SiloModel>>;
    abstract buscaSilosComPropriedades(order?: string): Observable<ListaRecursosInterface<any>>;
    abstract buscaSilosSensorPlenum(order?: string): Observable<ListaRecursosInterface<any>>;
    abstract editaInformacoesSilo(id: number, dados?: EditaInformacoesAeracaoPayloadInterface): Observable<any>;
    abstract buscaVariosComVolume(order?: string): Observable<ListaRecursosInterface<SiloComVolumeModel>>;
    abstract buscaSilosSensorCO2(): Observable<ListaRecursosInterface<SiloComVolumeModel>>;
}

@Injectable({
    providedIn: 'root',
})
export class SilosController implements ISilosController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/silo');
    }

    buscaVarios(): Observable<any> {
        const arquivado = { 'filter.arquivado': 'false' };
        this.transport.options.params = this.objectToAnyService.toQueryString({
            order: 'ASC',
            ...arquivado,
        });
        return this.transport.http.get(`${this.transport.path}/`, this.transport.options);
    }

    buscaVariosComAeradores(): Observable<any> {
        const arquivado = { 'filter.arquivado': 'false' };
        this.transport.options.params = this.objectToAnyService.toQueryString({
            order: 'ASC',
            ...arquivado,
        });
        return this.transport.http.get(`${this.transport.path}/aeradores`, this.transport.options);
    }

    buscaVariosComVolume(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/sensor-volume`);
    }
    buscaSilosComPropriedades(): Observable<any> {
        const arquivado = { 'filter.arquivado': 'false' };
        this.transport.options.params = this.objectToAnyService.toQueryString({
            order: 'ASC',
            ...arquivado,
        });
        return this.transport.http.get(`${this.transport.path}/propriedade`, this.transport.options);
    }

    buscaSilosSensorPlenum(): Observable<any> {
        const arquivado = { 'filter.arquivado': 'false' };
        this.transport.options.params = this.objectToAnyService.toQueryString({
            order: 'ASC',
            ...arquivado,
        });
        return this.transport.http.get(`${this.transport.path}/propriedade/sensor-plenum`, this.transport.options);
    }

    buscaSilosSensorCO2(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/sensor-co2`);
    }

    editaInformacoesSilo(id: number, dados?: EditaInformacoesAeracaoPayloadInterface): Observable<any> {
        return this.transport.http.patch<any>(`${this.transport.path}/aeracao/${id}`, dados);
    }
}
