import { NgModule } from '@angular/core';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { ClassicLayoutModule } from 'app/layout/layouts/vertical/classic/classic.module';
import { SharedModule } from 'app/shared/shared.module';
import { TranslocoRootModule } from 'app/transloco-root.module';

const layoutModules = [EmptyLayoutModule, ClassicLayoutModule];

@NgModule({
    declarations: [LayoutComponent],
    imports: [SharedModule, TranslocoRootModule, ...layoutModules],
    exports: [LayoutComponent, ...layoutModules],
})
export class LayoutModule {}
