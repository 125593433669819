import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { HistoricoAlteracoesProgramaInterface, ResponseInterface } from 'app/shared/interfaces';
import { ApiPaginacao } from 'app/shared/models';
import { IObjectToAnyService } from 'app/shared/services';

export abstract class IAlteracoesProgramaController {
    abstract buscarHistorico(
        armazenagemREf: number,
        params: ApiPaginacao
    ): Observable<ResponseInterface<HistoricoAlteracoesProgramaInterface>>;
}

@Injectable({
    providedIn: 'root',
})
export class AlteracoesProgramaController implements IAlteracoesProgramaController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/silo-programa-aeracao-historico');
    }

    buscarHistorico(
        armazenagemREf: number,
        params: ApiPaginacao
    ): Observable<ResponseInterface<HistoricoAlteracoesProgramaInterface>> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: params.page,
            limit: params.limit,
        });
        return this.transport.http.get<ResponseInterface<HistoricoAlteracoesProgramaInterface>>(
            `${this.transport.path}/silo/${armazenagemREf}`,
            {
                params: this.transport.options.params,
            }
        );
    }
}
