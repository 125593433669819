import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ClienteEnum, CoresWhiteLabelKeplerWeberEnum } from 'app/core/enums';

export abstract class IWhiteLabelService {
    abstract obterCliente(): string | void;
    abstract definirEstiloKeplerWeber(): void;
}

@Injectable({
    providedIn: 'root',
})
export class whiteLabelService {
    constructor() {
        localStorage.removeItem('whiteLabelCliente');
    }

    obterCliente(): string | void {
        const url = window.location.hostname;

        if (url.includes(ClienteEnum.KEPLER)) {
            localStorage.setItem('whiteLabelCliente', ClienteEnum.KEPLER);
            return ClienteEnum.KEPLER;
        }

        return;
    }

    definirEstiloKeplerWeber(): void {
        //Adicionando cliente ao local storage
        localStorage.setItem('whiteLabelCliente', ClienteEnum.KEPLER);

        //Imagens em variaveis globais
        environment.whiteLabel.logoLoginUrl = 'assets/images/logo/logo-kepler.png';
        environment.whiteLabel.logoRedirecionamentoUrl = 'assets/images/logo/logo-kepler.png';
        environment.whiteLabel.logoMenuFechadoUrl = 'assets/images/logo/logo-menu-kepler.png';
        environment.whiteLabel.logoMenuAbertoUrl = 'assets/images/logo/logo-navbar-kepler.png';
        environment.whiteLabel.imgMapaContatos = 'assets/img/mapa-contatos-kepler.gif';

        //Definindo paleta de cores
        document.documentElement.style.setProperty('--cor-primaria', CoresWhiteLabelKeplerWeberEnum.COR_PRIMARIA);
        document.documentElement.style.setProperty('--cor-secundaria', CoresWhiteLabelKeplerWeberEnum.COR_SECUNDARIA);
        document.documentElement.style.setProperty('--cor-terciaria', CoresWhiteLabelKeplerWeberEnum.COR_TERCIARIA);
        document.documentElement.style.setProperty(
            '--cor-primaria-opacidade',
            CoresWhiteLabelKeplerWeberEnum.COR_PRIMARIA_OPACIDADE
        );

        //Imagens em classes globais
        document.documentElement.style.setProperty(
            '--background-login',
            'url(/assets/images/background/login-kepler.png)'
        );
        document.documentElement.style.setProperty(
            '--background-redirecionamento',
            'url(/assets/images/background/redirecionamento-bg-kepler.png)'
        );
        document.documentElement.style.setProperty('--icone-silo', 'url(/assets/media/storage/silo-laranja.svg)');
        document.documentElement.style.setProperty(
            '--icone-temperatura',
            'url(/assets/media/storage/icone-temperatura-laranja.svg)'
        );
    }
}
