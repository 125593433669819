import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EstacaoMeteorologicaLocalObservable {
    private estacaoLocal: ReplaySubject<any> = new ReplaySubject<any>(1);

    set setEstacaoLocal(value: any) {
        this.estacaoLocal.next(value);
    }

    get estacaoLocal$(): Observable<any> {
        return this.estacaoLocal.asObservable();
    }
}
