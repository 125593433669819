<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-9 h-9 sm:w-7 sm:h-7 rounded-full mt-1 sm:mt-0" src="assets/images/avatars/blank.png">
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <img class="w-7 h-7 rounded-full" src="assets/images/avatars/blank.png">
        <span class="flex flex-col leading-none ml-3">
            <span class="mt-1.5 text-md font-medium">{{user.name ? (user.name | titlecase) : 'Nome' }}</span>
            <span class="mt-1.5 text-md font-medium">{{user.email ? user.email : 'E-mail'}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sair</span>
    </button>
</mat-menu>
