import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { ResponseInterface, EstacaoMeteorologicaInterface } from 'app/shared/interfaces';
import { Observable } from 'rxjs';

export abstract class IEstacaoMeteorologicaController {
    abstract buscaEstacoesMeteorologicas(): Observable<ResponseInterface<EstacaoMeteorologicaInterface>>;
}

@Injectable({
    providedIn: 'root',
})
export class EstacaoMeteorologicaController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/estacao-meteorologica');
    }

    buscaEstacoesMeteorologicas(): Observable<ResponseInterface<EstacaoMeteorologicaInterface>> {
        return this.transport.http.get<ResponseInterface<EstacaoMeteorologicaInterface>>(
            `${this.transport.path}/estacoes-com-equipamento-ultima-leitura`
        );
    }
}
