import { EquipamentoTipoEnum, EquipamentoCategoriaEnum } from "app/shared/enums/equipamento.enums";

export class EquipamentoModel {
  id: number;
  nome: string;
  categoria: EquipamentoCategoriaEnum;
  tipo: EquipamentoTipoEnum;
  silos: string[];
  instante: string;
  status: boolean;
}
