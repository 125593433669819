import { FuseAlertType } from '@fuse/components/alert';

export class TipoDeAlerta {
    tipo: FuseAlertType = 'success';
    titulo: string;
    tempo: number = 3000;
    mensagem?: string;
    mostrar?: boolean = false;

    constructor(partial?: Partial<TipoDeAlerta>) {
        Object.assign(this, partial);
    }
}
