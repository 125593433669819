import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { map, Observable } from 'rxjs';
import { EstacaoMeteorologicaLocalObservable } from 'app/shared/observables';
import { EstacaoMeteorologicaUnidadeModel } from 'app/shared/models';

export abstract class IEstacaoMeteorologicaLocalController {
    abstract buscaUmaEstacaoMeteorologica(): Observable<EstacaoMeteorologicaUnidadeModel>;
}

@Injectable({
    providedIn: 'root',
})
export class EstacaoMeteorologicaLocalController implements IEstacaoMeteorologicaLocalController {
    private transport: IApiTransportInterface;

    constructor(
        readonly apiTransportService: ApiTransportService,
        private estacaoMeteorologicaLocalService: EstacaoMeteorologicaLocalObservable
    ) {
        this.transport = this.apiTransportService.execute('/estacao-meteorologica-local');
    }

    buscaUmaEstacaoMeteorologica(): Observable<any> {
        return this.transport.http.get<any>(`${this.transport.path}`).pipe(
            map((response) => {
                this.estacaoMeteorologicaLocalService.setEstacaoLocal = response;
            })
        );
    }
}
