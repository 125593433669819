import { Injectable } from '@angular/core';

import { MENUS_ARMAZENAGEM } from 'app/core/aside-menu/constants/menus-armazenagem.constant';
import { MENUS_SECAGEM } from 'app/core/aside-menu/constants/menus-secagem.constant';
import { AsideMenuObservable } from 'app/shared/observables';
import { Menu } from 'app/core/aside-menu/models/menu.model';

@Injectable({
    providedIn: 'root',
})
export class AsideMenuService {
    private modules: Menu[] = [];

    constructor(private asideMenuObservable: AsideMenuObservable) {}

    getMenus(): void {
        this.modules = [];

        MENUS_ARMAZENAGEM.map((armazenagem: Menu) => this.modules.push(armazenagem));
        // MENUS_SECAGEM.map((secagem: Menu) => this.modules.push(secagem));

        this.asideMenuObservable.modulosSubject.next(this.modules);
    }

    nextMenu(menu: any): void {
        this.asideMenuObservable.menusSubject.next(menu.groups);
    }

    selectInitialModule(): Menu {
        return this.modules[0];
    }

    selectInitialMenu(moduloPosition?: any): Menu {
        return this.modules[moduloPosition ? moduloPosition : 0].groups[0].items[0];
    }
}
