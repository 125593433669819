import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ControleAcoesHistoricoComponent } from './controle-acoes-historico.component';

@NgModule({
    declarations: [ControleAcoesHistoricoComponent],
    imports: [CommonModule, MatIconModule],
    exports: [ControleAcoesHistoricoComponent],
})
export class ControleAcoesHistoricoModule {}
