export enum PpmEnum {
    NIVEIS_DO_AR_AMBIENTE = 'Níveis do ar ambiente',
    NIVEIS_NORMAIS = 'Níveis normais',
    DESTERIORACAO_INCIPIENTE = 'Deterioração incipiente',
    LEVE_INFESTACAO_DE_INSETOS_E_MICROORGANISMO = 'Leve infestação de insetos e microorganismos',
    ALTA_INFESTACAO_DE_INSETOS_E_MICROORGANISMO = 'Alta infestação de insetos e microorganismos',
    ELEVADA_DETERIORACAO = 'Elevada deterioração',
    NIVEIS_ALTAMENTE_INADEQUADOS = 'Níveis altemente inadequados',
    VALOR_INVALIDO = 'Valor inválido',
}
