import { Injectable } from '@angular/core';
import { PaletaDeCoresInterface } from 'app/shared/interfaces';

const paletaParaTemperatura: PaletaDeCoresInterface[] = [
    { min: 0, max: 5.99, fundo: '#6473F5', cor: '#FFFFFF' },
    { min: 6, max: 12, fundo: '#79CBF5', cor: '#00091F' },
    { min: 12.01, max: 18, fundo: '#64BD94', cor: '#00091F' },
    { min: 18.01, max: 24, fundo: '#64ED7B', cor: '#00091F' },
    { min: 24.01, max: 30, fundo: '#DEED7B', cor: '#00091F' },
    { min: 30.01, max: 36, fundo: '#DEC17B', cor: '#00091F' },
    { min: 36.01, max: 200, fundo: '#DE737B', cor: '#00091F' },
    { min: '', max: '', fundo: '#D6D6D6', cor: '#00091F' },
];

const paletaParaTabelaSensoresTemperatura: PaletaDeCoresInterface[] = [
    { min: '', max: '', fundo: '#CCCCCC', cor: '#00091F' },
    { min: '', max: '', fundo: '#FFFFFF', cor: '#00091F' },
    { min: 0, max: 5.99, fundo: '#6473F5', cor: '#FFFFFF' },
    { min: 6, max: 12, fundo: '#79CBF5', cor: '#00091F' },
    { min: 12.01, max: 18, fundo: '#64BD94', cor: '#00091F' },
    { min: 18.01, max: 24, fundo: '#64ED7B', cor: '#00091F' },
    { min: 24.01, max: 30, fundo: '#DEED7B', cor: '#00091F' },
    { min: 30.01, max: 36, fundo: '#DEC17B', cor: '#00091F' },
    { min: 36.01, max: 200, fundo: '#DE737B', cor: '#00091F' },
    ,
];

const paletaParaUmidade: PaletaDeCoresInterface[] = [
    { min: 0, max: 33, fundo: '#B5D5EA', cor: '#00091F' },
    { min: 34, max: 66, fundo: '#7DB9DC', cor: '#00091F' },
    { min: 67, max: 100, fundo: '#467D9D', cor: '#00091F' },
    { min: '', max: '', fundo: '#EBEEF2', cor: '#49697D' },
];

const paletaParaCO2: PaletaDeCoresInterface[] = [
    { min: '', max: '', fundo: '#EBEEF2', cor: '#00091F' },
    { min: 1, max: 380, fundo: '#6473F5', cor: '#FFFFFF' },
    { min: 381, max: 600, fundo: '#64ED7B', cor: '#00091F' },
    { min: 601, max: 1100, fundo: '#FEE874', cor: '#00091F' },
    { min: 1101, max: 3500, fundo: '#FFC83A', cor: '#00091F' },
    { min: 3501, max: 5000, fundo: '#EF9033', cor: '#00091F' },
    { min: 5001, max: 9000, fundo: '#E53838', cor: '#FFFFFF' },
    { min: 9001, max: 99999999, fundo: '#B05055', cor: '#FFFFFF' },
];

export abstract class ICriaPaletaCoresTermometriaService {
    abstract criarPaletaTemperatura(sensorTemperatura: number): PaletaDeCoresInterface;
    abstract criarPaletaTabelaSensoresTemperatura(
        sensorTemperatura: number,
        podeColorir: boolean
    ): PaletaDeCoresInterface;
    abstract criarPaletaUmidade(sensorUmidade: number): PaletaDeCoresInterface;
    abstract criarPaletaCO2(co2: number): PaletaDeCoresInterface;
}

@Injectable({ providedIn: 'root' })
export class CriaPaletaCoresTermometriaService implements ICriaPaletaCoresTermometriaService {
    criarPaletaTemperatura(sensorTemperatura: number): PaletaDeCoresInterface {
        let paletaCores;
        if (sensorTemperatura < 0) {
            sensorTemperatura = 0;
        }
        if (sensorTemperatura > 100) {
            sensorTemperatura = 100;
        }
        if (sensorTemperatura) {
            paletaCores = paletaParaTemperatura.filter(
                (temp: any) => sensorTemperatura >= temp.min && sensorTemperatura <= temp.max
            );
        } else {
            paletaCores = paletaParaTemperatura;
        }

        return paletaCores[0];
    }

    criarPaletaTabelaSensoresTemperatura(sensorTemperatura: number, podeColorir: boolean): PaletaDeCoresInterface {
        let paletaCores;
        if (podeColorir) {
            if (sensorTemperatura) {
                if (sensorTemperatura < 0) {
                    sensorTemperatura = 0;
                }
                if (sensorTemperatura > 100) {
                    sensorTemperatura = 100;
                }
                paletaCores = paletaParaTabelaSensoresTemperatura.filter(
                    (temp: any) => sensorTemperatura >= temp.min && sensorTemperatura <= temp.max
                );
                return paletaCores[0];
            }
            if (sensorTemperatura === null) {
                paletaCores = paletaParaTabelaSensoresTemperatura;
                return paletaCores[0];
            } else {
                paletaCores = paletaParaTabelaSensoresTemperatura;
                return paletaCores[1];
            }
        } else {
            paletaCores = paletaParaTabelaSensoresTemperatura;
            return paletaCores[1];
        }
    }

    criarPaletaUmidade(sensorUmidade: any): PaletaDeCoresInterface {
        let paletaCores;
        if (sensorUmidade) {
            paletaCores = paletaParaUmidade.filter(
                (humidity) => sensorUmidade >= humidity.min && sensorUmidade <= humidity.max
            );
        } else {
            paletaCores = paletaParaUmidade;
        }
        return paletaCores[0];
    }

    criarPaletaCO2(sensorCo2: number): PaletaDeCoresInterface {
        let paletaCores;
        if (sensorCo2) {
            if (sensorCo2 < 1) {
                sensorCo2 = 1;
            }
            if (sensorCo2 > 99999999) {
                sensorCo2 = 9001;
            }
            paletaCores = paletaParaCO2.filter(
                (co2: PaletaDeCoresInterface) => sensorCo2 >= co2.min && sensorCo2 <= co2.max
            );
        } else {
            paletaCores = paletaParaCO2;
        }
        return paletaCores[0];
    }
}
