import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    ControleAeracaoAutomaticaController,
    EquipamentosController,
    EstacaoMeteorologicaController,
    EstacaoMeteorologicaLocalController,
    IControleAeracaoAutomaticaController,
    IEquipamentosController,
    IEstacaoMeteorologicaController,
    IEstacaoMeteorologicaLocalController,
    IListagemProgramasController,
    IListagemRegrasController,
    ISilosController,
    IUnidadeParametrizacaoController,
    IUsuarioController,
    IVariavelAquecimentoController,
    ListagemProgramasController,
    ListagemRegrasController,
    SilosController,
    UnidadeParametrizacaoController,
    UsuarioController,
    VariavelAquecimentoController,
    ComandoAeracaoAutomaticaController,
    IComandoAeracaoAutomaticaController,
    IAlteracoesProgramaController,
    AlteracoesProgramaController,
} from 'app/shared/controllers';
import {
    ConfiguraEspacoSensoresMapaSiloService,
    ConfigurarStatusAeracaoService,
    ControleAcoesHistoricoService,
    ControleTrocaEstruturaArmazenagemService,
    CriaPaletaCoresTermometriaService,
    DefineCategoriaEquipamentoService,
    DefineGraficoService,
    DesenhaAneisVisaoSuperiorService,
    DesenhaPeundulosVisaoSuperiorService,
    DesestruturaStringFormulaListagemService,
    DesestruturaStringFormulaParaObjetoService,
    FormataCampoValorService,
    FormataEquipamentosComponenteDataService,
    IConfiguraEspacoSensoresMapaSiloService,
    IConfigurarStatusAeracaoService,
    IControleAcoesHistoricoService,
    IControleTrocaEstruturaArmazenagemService,
    ICriaPaletaCoresTermometriaService,
    IDefineCategoriaEquipamentoService,
    IDefineGraficoService,
    IDesenhaAneisVisaoSuperiorService,
    IDesenhaPeundulosVisaoSuperiorService,
    IDesestruturaStringFormulaListagemService,
    IDesestruturaStringFormulaParaObjetoService,
    IFormataCampoValorService,
    IFormataEquipamentosComponenteDataService,
    IObjectSubmitService,
    IObjectToAnyService,
    IOrdenarEstacoesService,
    IRemoveTimezoneData,
    IRetonaInstanteMaisRecenteAeradoresService,
    IRetonaInstanteMaisRecenteEquipamentoService,
    IRetornaDiasSemanaEnumService,
    IRetornaDiasSemanaExibicaoService,
    IRetornaEstiloStatusComunicacao,
    IRetornaObjetivoProgramaAeracaoService,
    IRetornaRangeEntreDatasService,
    IRetornaStatusComunicacaoService,
    ObjectSubmitService,
    ObjectToAnyService,
    OrdenarEstacoesService,
    RemoveTimezoneData,
    RetonaInstanteMaisRecenteAeradoresService,
    RetonaInstanteMaisRecenteEquipamentoService,
    RetornaDiasSemanaEnumService,
    RetornaDiasSemanaExibicaoService,
    RetornaEstiloStatusComunicacao,
    RetornaObjetivoProgramaAeracaoService,
    RetornaRangeEntreDatasService,
    RetornaStatusComunicacaoService,
    IDefineGraficoBarrasService,
    DefineGraficoBarrasService,
} from 'app/shared/services';
import { IUnidadeProdutosObservable, UnidadeProdutosObservable } from 'app/shared/observables';
import { TranslocoRootModule } from 'app/transloco-root.module';
@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslocoRootModule],
    providers: [
        DecimalPipe,
        { provide: IObjectToAnyService, useClass: ObjectToAnyService },
        { provide: IObjectSubmitService, useClass: ObjectSubmitService },
        { provide: IObjectSubmitService, useClass: ObjectSubmitService },
        {
            provide: ICriaPaletaCoresTermometriaService,
            useClass: CriaPaletaCoresTermometriaService,
        },
        {
            provide: IDefineCategoriaEquipamentoService,
            useClass: DefineCategoriaEquipamentoService,
        },
        { provide: IDefineGraficoService, useClass: DefineGraficoService },
        { provide: ISilosController, useClass: SilosController },
        { provide: IEstacaoMeteorologicaController, useClass: EstacaoMeteorologicaController },
        { provide: IRetornaRangeEntreDatasService, useClass: RetornaRangeEntreDatasService },
        { provide: IEquipamentosController, useClass: EquipamentosController },
        { provide: IEstacaoMeteorologicaLocalController, useClass: EstacaoMeteorologicaLocalController },
        { provide: IRemoveTimezoneData, useClass: RemoveTimezoneData },
        { provide: IOrdenarEstacoesService, useClass: OrdenarEstacoesService },
        { provide: IRetornaStatusComunicacaoService, useClass: RetornaStatusComunicacaoService },
        {
            provide: IRetonaInstanteMaisRecenteEquipamentoService,
            useClass: RetonaInstanteMaisRecenteEquipamentoService,
        },
        {
            provide: IRetornaEstiloStatusComunicacao,
            useClass: RetornaEstiloStatusComunicacao,
        },
        {
            provide: IConfiguraEspacoSensoresMapaSiloService,
            useClass: ConfiguraEspacoSensoresMapaSiloService,
        },
        {
            provide: IUnidadeParametrizacaoController,
            useClass: UnidadeParametrizacaoController,
        },
        {
            provide: IListagemRegrasController,
            useClass: ListagemRegrasController,
        },
        {
            provide: IDesestruturaStringFormulaListagemService,
            useClass: DesestruturaStringFormulaListagemService,
        },
        {
            provide: IConfigurarStatusAeracaoService,
            useClass: ConfigurarStatusAeracaoService,
        },
        {
            provide: IUnidadeProdutosObservable,
            useClass: UnidadeProdutosObservable,
        },
        {
            provide: IRetonaInstanteMaisRecenteAeradoresService,
            useClass: RetonaInstanteMaisRecenteAeradoresService,
        },
        {
            provide: IListagemProgramasController,
            useClass: ListagemProgramasController,
        },
        {
            provide: IRetornaObjetivoProgramaAeracaoService,
            useClass: RetornaObjetivoProgramaAeracaoService,
        },
        {
            provide: IDesenhaPeundulosVisaoSuperiorService,
            useClass: DesenhaPeundulosVisaoSuperiorService,
        },
        {
            provide: IDesenhaAneisVisaoSuperiorService,
            useClass: DesenhaAneisVisaoSuperiorService,
        },
        {
            provide: IControleAeracaoAutomaticaController,
            useClass: ControleAeracaoAutomaticaController,
        },
        {
            provide: IControleAcoesHistoricoService,
            useClass: ControleAcoesHistoricoService,
        },
        {
            provide: IVariavelAquecimentoController,
            useClass: VariavelAquecimentoController,
        },
        {
            provide: IFormataEquipamentosComponenteDataService,
            useClass: FormataEquipamentosComponenteDataService,
        },
        {
            provide: IFormataCampoValorService,
            useClass: FormataCampoValorService,
        },
        {
            provide: IUsuarioController,
            useClass: UsuarioController,
        },
        {
            provide: IComandoAeracaoAutomaticaController,
            useClass: ComandoAeracaoAutomaticaController,
        },
        {
            provide: IAlteracoesProgramaController,
            useClass: AlteracoesProgramaController,
        },
        {
            provide: IControleTrocaEstruturaArmazenagemService,
            useClass: ControleTrocaEstruturaArmazenagemService,
        },
        {
            provide: IDesestruturaStringFormulaParaObjetoService,
            useClass: DesestruturaStringFormulaParaObjetoService,
        },
        {
            provide: IRetornaDiasSemanaExibicaoService,
            useClass: RetornaDiasSemanaExibicaoService,
        },
        {
            provide: IRetornaDiasSemanaEnumService,
            useClass: RetornaDiasSemanaEnumService,
        },
        {
            provide: IDefineGraficoBarrasService,
            useClass: DefineGraficoBarrasService,
        },
    ],
    exports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class SharedModule {}
