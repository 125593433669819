import { TipoGraficoEnum } from 'app/shared/enums';
import { graficoTraducao } from 'app/shared/constants';
import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { GraficoOpcoes } from 'app/shared/interfaces';

export abstract class IDefineGraficoService {
    abstract execute(
        series: any[],
        categorias: any[],
        toolbar?: boolean,
        tipoGrafico?: TipoGraficoEnum
    ): Partial<GraficoOpcoes>;
}

@Injectable({ providedIn: 'root' })
export class DefineGraficoService implements IDefineGraficoService {
    constructor(private decimalPipe: DecimalPipe) {}

    execute(
        series: any[],
        categorias: any[],
        toolbar = false,
        tipoGrafico = TipoGraficoEnum.TEMPERATURA_INTERNA
    ): Partial<GraficoOpcoes> {
        let thisAuxiliar = this;
        const unidadeMedida = tipoGrafico === TipoGraficoEnum.TEMPERATURA_INTERNA ? ' (°C)' : ' (%)';
        return {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
                fontFamily: 'obvia-wide-regular',
                defaultLocale: 'pt-br',
                locales: [graficoTraducao],
                toolbar: {
                    show: toolbar,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 4,
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 7,
                },
            },
            grid: {
                borderColor: '#f1f1f1',
            },
            series: series,
            xaxis: {
                labels: {
                    trim: false,
                    showDuplicates: false,
                },
                categories: categorias,
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return thisAuxiliar.decimalPipe.transform(value, '1.1-1');
                    },
                },
            },
            tooltip: {
                y: {
                    title: {
                        formatter: function (val: any) {
                            return val + unidadeMedida;
                        },
                    },
                },
            },
        } as Partial<GraficoOpcoes>;
    }
}
