import { BehaviorSubject, Observable } from 'rxjs';

export class ColorirFundoTabelaObservable {
    colorirFundoTabela: BehaviorSubject<any | null> = new BehaviorSubject(null);

    get colorirFundoTabela$(): Observable<any> {
        return this.colorirFundoTabela.asObservable();
    }

    set habilitaColorirFundoTabela(value: any) {
        this.colorirFundoTabela.next(value);
    }
}
