import { InformacaoesPaginaObservable } from 'app/shared/observables';
import { Injectable } from '@angular/core';
import { TituloPaginaModel } from 'app/shared/models';
export abstract class ISetaTituloPaginaService {
    abstract setaTituloPaginaService(object: any): void;
}

@Injectable({ providedIn: 'root' })
export class SetaTituloPaginaService implements ISetaTituloPaginaService {
    constructor(private informacaoesPagina: InformacaoesPaginaObservable) {}
    public setaTituloPaginaService(titulo: TituloPaginaModel): void {
        this.informacaoesPagina.setaModuloSelecionado = titulo.modulo;
        this.informacaoesPagina.setaPaginaSelecionada = titulo.titulo;
        this.informacaoesPagina.setaSubMenuSelecionado = titulo.subtitulo;
        this.informacaoesPagina.setaCategoriaSelecionada = titulo.categoria;
    }
}
