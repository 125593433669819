import { BehaviorSubject, Observable } from 'rxjs';
import { AmostragemEstadoModel } from 'app/shared/models';

export class RegraSelecionadaObservable {
    private regraSelecionada: BehaviorSubject<AmostragemEstadoModel> = new BehaviorSubject(null);

    get regraSelecionada$(): Observable<AmostragemEstadoModel> {
        return this.regraSelecionada.asObservable();
    }

    set setaRegraSelecionada(valor: AmostragemEstadoModel) {
        this.regraSelecionada.next(valor);
    }
}
