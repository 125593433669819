import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { HistoricoControleAeracaoInterface, ResponseInterface } from 'app/shared/interfaces';
import { ApiPaginacao } from 'app/shared/models';
import { IObjectToAnyService } from 'app/shared/services';

export abstract class IControleAeracaoAutomaticaController {
    abstract buscarHistorico(
        armazenagemREf: number,
        params: ApiPaginacao
    ): Observable<ResponseInterface<HistoricoControleAeracaoInterface>>;
}

@Injectable({
    providedIn: 'root',
})
export class ControleAeracaoAutomaticaController implements IControleAeracaoAutomaticaController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/controle-aeracao-automatica');
    }

    buscarHistorico(
        armazenagemREf: number,
        params: ApiPaginacao
    ): Observable<ResponseInterface<HistoricoControleAeracaoInterface>> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: params.page,
            limit: params.limit,
        });

        return this.transport.http.get<ResponseInterface<HistoricoControleAeracaoInterface>>(
            `${this.transport.path}/${armazenagemREf}`,
            {
                params: this.transport.options.params,
            }
        );
    }
}
