import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';

export abstract class IFormataCampoValorService {
    abstract execute(valor: number | string, formato?: string): number | string;
}

@Injectable({ providedIn: 'root' })
export class FormataCampoValorService implements IFormataCampoValorService {
    constructor(private decimalPipe: DecimalPipe) {}

    public execute(valor: number | string, formato?: string): number | string {
        if (valor) {
            if (typeof valor === 'number') {
                return this.decimalPipe.transform(valor, formato ?? '1.1-1');
            } else {
                return valor;
            }
        } else {
            return '-';
        }
    }
}
