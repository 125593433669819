import { Injectable } from '@angular/core';

export abstract class IConfiguraEspacoSensoresMapaSiloService {
    abstract adequaEspacamentoSensores(configuracao?: string): string;
    abstract colocaSensoresMesmaLinha(configuracao?: string): string;
}

@Injectable({ providedIn: 'root' })
export class ConfiguraEspacoSensoresMapaSiloService implements IConfiguraEspacoSensoresMapaSiloService {
    public adequaEspacamentoSensores(configuracao?: string): string {
        let min = 0;
        let max = 0;
        let numeroSensoresArrayInt = [];
        let arraySensoresTelhado = [];
        const numeroSensoresArrayString = configuracao.split(';');
        numeroSensoresArrayString.forEach((sensor) => {
            numeroSensoresArrayInt.push(parseInt(sensor));
        });
        min = Math.min(...numeroSensoresArrayInt);
        numeroSensoresArrayInt.forEach((sensor) => {
            if (sensor > min) {
                arraySensoresTelhado.push(sensor - min);
            } else {
                arraySensoresTelhado.push(0);
            }
        });
        return arraySensoresTelhado.join(';');
    }

    public colocaSensoresMesmaLinha(configuracao?: string): string {
        let arrayNumeroPendulos = [];
        const numeroSensoresArrayString = configuracao.split(';');
        numeroSensoresArrayString.forEach(() => {
            arrayNumeroPendulos.push(1);
        });
        return arrayNumeroPendulos.join(';');
    }
}
