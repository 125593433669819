<div class="w-full flex flex-col px-4">
    <div class="flex w-full items-center">
        <span class="font-bold text-sm sm:min-w-28 hidden sm:block"> Última </span>
        <span
            class="text-sm min-w-[33.33%] sm:min-w-40 flex flex-col sm:flex-row"
            matTooltip="Momento da última sincronização de dados entre o sistema local e o sistema em nuvem"
            matTooltipClass="tooltip-padrao"
            *ngIf="!objetoVazio"
        >
            <span class="order-1 sm:order-none">Local/</span>
            <span class="order-1 sm:order-none">Nuvem</span>
        </span>
        <span class="text-sm min-w-[33.33%] sm:min-w-40 flex flex-col sm:flex-row" *ngIf="objetoVazio">
            <span class="order-1 sm:order-none">Local/</span>
            <span class="order-1 sm:order-none">Nuvem</span>
        </span>
        <span class="text-sm min-w-[33.33%] sm:min-w-48 flex sm:block items-center justify-center">
            <div
                class="circle mr-1 sm:mr-[6px]"
                *ngIf="!objetoVazio"
                [ngClass]="{
                    'com-execucao': dataSincronizador?.local_nuvem?.status,
                    'sem-execucao': !dataSincronizador?.local_nuvem?.status
                }"
            ></div>
            <div class="circle sem-execucao" *ngIf="objetoVazio"></div>
            <div class="inline-block sm:block">
                <span *ngIf="!objetoVazio" class="block sm:inline">
                    {{ dataSincronizador?.local_nuvem?.instante | date: 'dd/MM/yyyy' }}
                </span>
                <span *ngIf="!objetoVazio" class="block sm:inline">
                    {{ dataSincronizador?.local_nuvem?.instante | date: 'HH:mm:ss' }}
                </span>
            </div>
            <span *ngIf="objetoVazio">Sem registro</span>
        </span>
        <ng-container *ngIf="!objetoVazio">
            <span
                class="text-sm min-w-[33.33%] sm:min-w-max cursor-pointer"
                (click)="mostrarHistorico(tipoSincronizadorEnum.NuvemLocal)"
            >
                <div class="equipamento com-execucao" *ngIf="dataSincronizador?.local_nuvem?.status">
                    <span class="flex flex-col items-center sm:block"
                        >Em execução
                        <mat-icon class="icone-status inline min-w-0 sm:inline-flex sm:w-6 sm:min-w-6">
                            check_circle
                        </mat-icon>
                    </span>
                </div>
                <div class="equipamento sem-execucao" *ngIf="!dataSincronizador?.local_nuvem?.status">
                    <span class="flex flex-col items-center sm:block"
                        >Sem comunicação
                        <mat-icon class="icone-status inline min-w-0 sm:inline-flex sm:w-6 sm:min-w-6">
                            warning
                        </mat-icon>
                    </span>
                </div>
            </span>
        </ng-container>
        <ng-container *ngIf="objetoVazio">
            <span class="text-sm min-w-[33.33%] sm:min-w-max flex justify-end"> Indisponível </span>
        </ng-container>
    </div>

    <div class="flex w-full justify-between items-center">
        <span class="font-bold text-sm sm:min-w-28 hidden sm:block"> sincronização: </span>
        <span
            class="text-sm min-w-[33.33%] sm:min-w-40 flex flex-col sm:flex-row"
            matTooltip="Momento da última sincronização de dados entre o coletor de dados e os equipamentos"
            matTooltipClass="tooltip-padrao"
            *ngIf="!objetoVazio"
        >
            <span class="order-1 sm:order-none">Coletor/</span>
            <span class="order-1 sm:order-none">Sincronizador</span>
        </span>
        <span class="text-sm min-w-[33.33%] sm:min-w-40 flex flex-col sm:flex-row" *ngIf="objetoVazio">
            <span class="order-1 sm:order-none">Coletor/</span>
            <span class="order-1 sm:order-none">Sincronizador</span>
        </span>

        <span class="text-sm min-w-[33.33%] sm:min-w-48 flex sm:block items-center justify-center">
            <ng-container *ngIf="tipoAmbiente === 'LOCAL'">
                <div
                    class="circle"
                    *ngIf="!objetoVazio"
                    [ngClass]="{
                        'com-execucao': dataSincronizador?.coletor?.status,
                        'sem-execucao': !dataSincronizador?.coletor?.status
                    }"
                ></div>
                <div class="circle sem-execucao" *ngIf="objetoVazio"></div>
                <div class="inline-block sm:block">
                    <span *ngIf="!objetoVazio" class="block sm:inline">
                        {{ dataSincronizador?.local_nuvem?.instante | date: 'dd/MM/yyyy' }}
                    </span>
                    <span *ngIf="!objetoVazio" class="block sm:inline">
                        {{ dataSincronizador?.local_nuvem?.instante | date: 'HH:mm:ss' }}
                    </span>
                </div>
                <span *ngIf="objetoVazio">Sem registro</span>
            </ng-container>

            <ng-container *ngIf="tipoAmbiente === 'NUVEM'">
                <div
                    class="circle mr-1 sm:mr-[6px]"
                    *ngIf="!objetoVazio"
                    [ngClass]="{
                        'com-execucao': dataSincronizador?.coletor?.status,
                        'sem-execucao': !dataSincronizador?.coletor?.status
                    }"
                ></div>
                <div class="circle sem-execucao" *ngIf="objetoVazio"></div>
                <div class="inline-block sm:block">
                    <span *ngIf="!objetoVazio" class="block sm:inline">
                        {{ dataSincronizador?.local_nuvem?.instante | date: 'dd/MM/yyyy' }}
                    </span>
                    <span *ngIf="!objetoVazio" class="block sm:inline">
                        {{ dataSincronizador?.local_nuvem?.instante | date: 'HH:mm:ss' }}
                    </span>
                </div>
                <span *ngIf="objetoVazio">Sem registro</span>
            </ng-container>
        </span>

        <ng-container *ngIf="tipoAmbiente === 'LOCAL'">
            <span
                class="text-sm min-w-max cursor-pointer"
                [ngClass]="{ 'min-w-[33.33%]': !objetoVazio }"
                (click)="mostrarHistorico(tipoSincronizadorEnum.ColetorSincronizador)"
            >
                <div *ngIf="!objetoVazio" class="min-w-[33.33%] sm:min-w-max">
                    <div class="equipamento com-execucao" *ngIf="dataSincronizador?.coletor?.status">
                        <span class="flex flex-col items-center sm:block"
                            >Em execução
                            <mat-icon class="icone-status inline min-w-0 sm:inline-flex sm:w-6 sm:min-w-6">
                                check_circle
                            </mat-icon>
                        </span>
                    </div>
                    <div class="equipamento sem-execucao" *ngIf="!dataSincronizador?.coletor?.status">
                        <span class="flex flex-col items-center sm:block"
                            >Sem comunicação
                            <mat-icon class="icone-status inline min-w-0 sm:inline-flex sm:w-6 sm:min-w-6"
                                >warning</mat-icon
                            >
                        </span>
                    </div>
                </div>
            </span>
            <span class="text-sm min-w-[33.33%] sm:min-w-max flex justify-end" *ngIf="objetoVazio">Indisponível</span>
        </ng-container>

        <ng-container *ngIf="tipoAmbiente === 'NUVEM'">
            <span
                class="text-sm sm:min-w-max cursor-pointer"
                [ngClass]="{ 'min-w-[33.33%]': !objetoVazio }"
                (click)="mostrarHistorico(tipoSincronizadorEnum.ColetorSincronizador)"
            >
                <div *ngIf="!objetoVazio" class="min-w-[33.33%] sm:min-w-max">
                    <div class="equipamento com-execucao" *ngIf="dataSincronizador?.coletor?.status">
                        <span class="flex flex-col items-center sm:block"
                            >Em execução
                            <mat-icon class="icone-status inline min-w-0 sm:inline-flex sm:w-6 sm:min-w-6">
                                check_circle
                            </mat-icon>
                        </span>
                    </div>
                    <div class="equipamento sem-execucao" *ngIf="!dataSincronizador?.coletor?.status">
                        <span class="flex flex-col items-center sm:block"
                            >Sem comunicação
                            <mat-icon class="icone-status inline min-w-0 sm:inline-flex sm:w-6 sm:min-w-6">
                                warning
                            </mat-icon>
                        </span>
                    </div>
                </div>
            </span>
            <span class="text-sm min-w-[33.33%] sm:min-w-max flex justify-end" *ngIf="objetoVazio"> Indisponível </span>
        </ng-container>
    </div>
</div>
