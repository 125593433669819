export const MENUS_ARMAZENAGEM = [
    {
        menu: 'ARMAZENAGEM',
        description: 'ARMAZENAGEM',
        link: 'armazenagem-visualizacao/painel-geral',
        icon: 'icon-module-armazenagem',
        separator: false,
        permission: true,
        groups: [
            {
                title: 'Visualização',
                icon: 'grid_view',
                separator: true,
                items: [
                    {
                        menu: 'Painel Geral',
                        icon: 'dashboard',
                        link: 'armazenagem-visualizacao/painel-geral',
                    },
                    // {
                    //     menu: 'Planta da Unidade',
                    //     icon: 'icon-menu-planta-unidade',
                    //     link: 'page-builder',
                    // },
                    {
                        menu: 'Silos',
                        icon: 'icon-menu-silos',
                        link: 'armazenagem-visualizacao/silos',
                    },
                    // {
                    //     menu: 'Armazéns',
                    //     icon: 'icon-menu-armazens',
                    //     link: 'page-builder',
                    // },
                ],
            },
            {
                title: 'Análise',
                icon: 'query_stats',
                separator: true,
                items: [
                    {
                        menu: 'Relatórios',
                        icon: 'insert_chart',
                        childrensItems: [
                            {
                                menu: 'Termometria',
                                icon: 'device_thermostat',
                                link: 'armazenagem-analise/termometria',
                            },
                            {
                                menu: 'Aeração - Funcionamento',
                                icon: 'icon-menu-aeracao-tempo',
                                link: 'armazenagem-analise/aeracao-funcionamento',
                            },
                            {
                                menu: 'Sensor do Plenum',
                                icon: 'icon-menu-sensor-plenum',
                                link: 'armazenagem-analise/sensor-plenum',
                            },
                            // {
                            //     menu: 'Aeração x Programa',
                            //     icon: 'icon-menu-aeracao-programa',
                            //     link: 'page-builder',
                            // },
                            {
                                menu: 'Aeração - Eq. Higroscópico',
                                icon: 'waterfall_chart',
                                link: 'armazenagem-analise/aeracao-equilibrio-higroscopico',
                            },
                            {
                                menu: 'Medidor de Volume',
                                icon: 'icone-volumetria-white',
                                link: 'armazenagem-analise/volumetria',
                            },
                            // {
                            //     menu: 'Umid. do Grão x Un. Armaz.',
                            //     icon: 'icon-menu-umidade-grao',
                            //     link: 'page-builder',
                            // },
                            // {
                            //     menu: 'Leituras Auxiliares',
                            //     icon: 'icon-menu-leitura-auxiliar',
                            //     link: 'page-builder',
                            // },
                            {
                                menu: 'Sensor de CO<sub>2</sub>',
                                icon: 'icon-menu-leitura-co2',
                                link: 'armazenagem-analise/sensor-co2',
                            },
                            // {
                            //     menu: 'Grão x Secagem',
                            //     icon: 'icon-menu-grao-secagem',
                            //     link: 'page-builder',
                            // },
                            // {
                            //     menu: 'Pluviômetro x Estação',
                            //     icon: 'icon-menu-pluviometro-estacao',
                            //     link: 'page-builder',
                            // },
                        ],
                    },
                    {
                        menu: 'Gráficos',
                        icon: 'area_chart',
                        childrensItems: [
                            {
                                menu: 'Unidade Armazenadora',
                                icon: 'icon-menu-unidade-armazenadora',
                                link: 'armazenagem-analise/graficos/unidade-armazenadora',
                            },
                            // {
                            //     menu: 'Histórico Un. Armaz.',
                            //     icon: 'icon-menu-relatorios',
                            //     link: 'page-builder',
                            // },
                             {
                                 menu: 'Pluviometria',
                                 icon: 'icon-menu-pluviometria',
                                 link: 'armazenagem-analise/graficos/pluviometria',
                             },
                        ],
                    },
                    {
                        menu: 'Eventos',
                        icon: 'article',
                        link: 'armazenagem-analise/eventos',
                    },
                    // {
                    //     menu: 'Armazenagem Preditiva',
                    //     icon: 'icon-menu-armazenagem-preditiva',
                    //     link: 'page-builder',
                    // },
                ],
            },
            {
                title: 'Cadastro',
                icon: 'article',
                separator: true,
                items: [
                    {
                        menu: 'Amostragens',
                        icon: 'icon-menu-amostragens',
                        link: 'armazenagem-cadastro/amostragens',
                    },
                    {
                        menu: 'Grãos',
                        icon: 'grain',
                        link: 'armazenagem-cadastro/graos',
                    },
                    {
                        menu: 'Programas',
                        icon: 'border_outer',
                        link: 'armazenagem-cadastro/programas',
                    },
                    {
                        menu: 'Regras',
                        icon: 'margin',
                        link: 'armazenagem-cadastro/regras',
                    },
                ],
            },
            // {
            //     title: 'Monitoramento',
            //     icon: 'icon-grupo-monitoramento',
            //     separator: true,
            //     items: [
            //         {
            //             menu: 'Analisadores',
            //             icon: 'icon-menu-analisadores',
            //             link: 'page-builder',
            //         },
            //     ],
            // },
            // {
            //     title: 'Configuração',
            //     icon: 'icon-grupo-configuracoes',
            //     separator: false,
            //     items: [
            //         {
            //             menu: 'Regras',
            //             icon: 'icon-menu-regras',
            //             link: 'page-builder',
            //         },
            //         {
            //             menu: 'Programas',
            //             icon: 'icon-menu-programas',
            //             link: 'page-builder',
            //         },
            //         {
            //             menu: 'Gestão de Energia',
            //             icon: 'icon-menu-gestao-energia',
            //             link: 'page-builder',
            //         },
            //         {
            //             menu: 'Variáveis de Aquecimento',
            //             icon: 'icon-menu-variaveis-aquecimento',
            //             link: 'page-builder',
            //         },
            //         {
            //             menu: 'Unidade Armazenadora',
            //             icon: 'icon-menu-unidade-armazenadora',
            //             link: 'page-builder',
            //         },
            //     ],
            // },
            {
                title: 'Configuração',
                icon: 'settings',
                separator: true,
                items: [
                    {
                        menu: 'Estoque',
                        icon: 'icone-menu-estoque',
                        link: 'armazenagem-configuracao/estoque',
                    },
                ],
            },
        ],
    },
];
