import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { FirstAccessObservable } from 'app/modules/auth/observables';
import { UsuarioController } from 'app/shared/controllers';
import { Observable, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CanFirstAccessGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly router: Router,
        private firstAccessObservable$: FirstAccessObservable,
        private usuarioController: UsuarioController,
        private authService: AuthService
    ) {}

    public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((res) => {
            const url: string = localStorage.getItem('urlAtual');
            if (url === 'sign-in-first') {
                res(true);
            } else {
                this.usuarioController
                    .verificarPrimeiroAcesso(localStorage.getItem('token_hash'))
                    .pipe(take(1))
                    .subscribe({
                        next: (firstAccess) => {
                            if (firstAccess.primeiro_acesso) {
                                this.firstAccessObservable$.setFirstAccessObservable(firstAccess.primeiro_acesso);
                                if (!url || url === '/first-access') {
                                    this.router.navigate(['/signed-in-redirect']);
                                } else {
                                    this.router.navigate([url]);
                                }
                            } else {
                                res(true);
                            }
                        },
                        error: (error) => {
                            this.authService.signOut();
                            localStorage.clear();
                            this.router.navigate(['/sign-in']);
                        },
                    });
            }
        });
    }

    public canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate();
    }
}
