import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AeradorInterface, PosicaoAeradorInterface } from 'app/shared/interfaces';
import { IConfigurarStatusAeracaoService } from 'app/shared/services';

const calculoPosicaoAeradores = {
    xAeradoresNaBase: 1.5,
    yAeradoresNaBase: 0.15,
    xAeradoresNaSuperior: 1.2,
    yAeradoresNaSuperior: 0.4,
};
@Component({
    selector: 'app-aeradores-visao-silo',
    templateUrl: './aeradores-visao-silo.component.html',
    styleUrls: ['./aeradores-visao-silo.component.scss'],
})
export class AeradoresVisaoSiloComponent implements OnChanges, OnInit {
    @Input() listaAeradores?: AeradorInterface[];
    aeradores: PosicaoAeradorInterface[] = [];
    aerador1: PosicaoAeradorInterface;
    aerador2: PosicaoAeradorInterface;
    aerador3: PosicaoAeradorInterface;
    aerador4: PosicaoAeradorInterface;
    eventSilo: any;

    constructor(private configurarStatusAeracaoService: IConfigurarStatusAeracaoService) {}
    ngOnInit(): void {
        if (this.listaAeradores.length > 0) {
            document.addEventListener('retornaCentroCanva', (event) => {
                this.eventSilo = event;
                this.listaAeradores.forEach((aerador, index) => {
                    this.configuraAeradores(event, index);
                });
            });
        }
    }

    ngOnChanges(change: SimpleChanges) {
        if (change.listaAeradores.previousValue) {
            this.aeradores = [];
            this.listaAeradores = change.listaAeradores.currentValue;
            this.listaAeradores.forEach((aerador, index) => {
                this.configuraAeradores(this.eventSilo, index);
            });
        }
    }

    configuraAeradores(event, indexAerador) {
        const aerador = document.getElementById(`aerador${indexAerador + 1}`)?.getBoundingClientRect();
        if (this.aeradores.length === this.listaAeradores.length) return;
        this.aeradores.push(this.posicaoAerador(event, indexAerador, aerador));
    }

    posicaoAerador(event: any, indexAerador: any, aerador: any): PosicaoAeradorInterface {
        const configuracaoStatusAerador = this.configurarStatusAeracaoService.execute(
            this.listaAeradores[indexAerador].aeracao.status
        );
        const posicaoY =
            indexAerador <= 1 ? calculoPosicaoAeradores.yAeradoresNaBase : calculoPosicaoAeradores.yAeradoresNaSuperior;
        const posicaoX =
            indexAerador <= 1 ? calculoPosicaoAeradores.xAeradoresNaBase : calculoPosicaoAeradores.xAeradoresNaSuperior;
        return {
            x: `-${event.detail.x * posicaoX + +aerador.width}px`,
            y: `-${event.detail.y * posicaoY + aerador.height}px`,
            imagem: configuracaoStatusAerador?.aerador.cor ? `${configuracaoStatusAerador?.aerador.cor}.png` : '',
        };
    }
}
