export enum SiloBaseEnum {
    PLANA = 'plana',
    EM_V = 'em_v',
    SEMI_V = 'semi_v',
}

export enum SiloTipoEnum {
    PULMAO = 'pulmao',
    ARMAZENAGEM = 'armazenagem',
}
  