import { ObjetivoProgramaEnum } from 'app/shared/enums';

export class ProgramaHistoricoModel {
    id: number;
    instante: string;
    nome_antigo: string;
    nome_novo: string;
    nome_usuario: string;
    objetivo_programa_antigo: ObjetivoProgramaEnum;
    objetivo_programa_novo: ObjetivoProgramaEnum;
    programa_aeracao_id: number;
    sobrenome_usuario: string;
    usuario_ref: number;

    constructor(partial?: Partial<ProgramaHistoricoModel>) {
        Object.assign(this, partial);
    }
}
