export enum VariaveisControleEnum {
    CHPLUVMILIMETROS = 'CHPLUVMILIMETROS',
    CHPLUVSEMCHUVA = 'CHPLUVSEMCHUVA',
    TE = 'TE',
    UR = 'UR',
    COMPROD = 'COMPROD',
    DI = 'DI',
    EQLHIGEST = 'EQLHIGEST',
    PEND_UR_MAX = 'PEND_UR_MAX',
    PEND_UR_MEDIA = 'PEND_UR_MEDIA',
    PEND_UR_MIN = 'PEND_UR_MIN',
    QTDESACOSPROD = 'QTDESACOSPROD',
    'TE - TI' = 'TE - TI',
    TEORUMID = 'TEORUMID',
    TI = 'TI',
    TI_DIG = 'TI_DIG',
    TI_TERM = 'TI_TERM',
    TMAX = 'TMAX',
    TMAX_DIG = 'TMAX_DIG',
    TMAX_TERM = 'TMAX_TERM',
    TMIN = 'TMIN',
    TMIN_DIG = 'TMIN_DIG',
    TMIN_TERM = 'TMIN_TERM',
    DIASEMANA = 'DIASEMANA',
    DIAUTIL = 'DIAUTIL',
    HRSLIGAR = 'HRSLIGAR',
    HRSPROIB = 'HRSPROIB',
    HRSPROIBALL = 'HRSPROIBALL',
    TEMPODECHORAS = 'TEMPODECHORAS',
    TPLENUM = 'TPLENUM',
    URPLENUM = 'URPLENUM',
}
