import { Injectable } from "@angular/core";
import { InformacoesGeraisSiloGraoModel, ProdutoModel, ProdutosModel } from "app/shared/models";

@Injectable({
    providedIn: 'root',
})

export class ProdutosService {
    selecionaNomeProduto(idProduto: number, produtos: ProdutoModel[]): string {
        let nomeProduto: string;
        let listaProdutos = [];

        Object.keys(produtos).forEach(p => {
            if(p === 'data') { listaProdutos = produtos[p] }
        });

        listaProdutos.forEach(p => {
            if(p.id == idProduto) {
                nomeProduto = p.nome;
                nomeProduto = nomeProduto[0].toUpperCase() + nomeProduto.substring(1);
            }
        });

        return nomeProduto;
    }

    selecionaGrao(grao: InformacoesGeraisSiloGraoModel): Partial<ProdutosModel> {
        let nome: string;
        let tipo_ref: number;
        let graoSelecionado = grao.data[0].produto;

        Object.keys(graoSelecionado).forEach(key => {
            if(key === 'grao') { nome = graoSelecionado[key] }
            if(key === 'produto_tipo_ref') { tipo_ref = graoSelecionado[key] }
        });

        return {
            nome: nome[0].toUpperCase() + nome.substring(1),
            produto_tipo_ref: tipo_ref
        };
    }
}
