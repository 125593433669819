import { Injectable } from '@angular/core';
import { ObjetoGernericoParaSelectModel } from 'app/shared/models';
import { RetornaIndexArrayService } from 'app/shared/services';
export abstract class IControleTrocaEstruturaArmazenagemService {
    abstract voltarAnterior(controle: any, lista: ObjetoGernericoParaSelectModel[]): any;
    abstract voltarPrimeiro(controle: any, lista: ObjetoGernericoParaSelectModel[]): any;
    abstract avancarProximo(controle: any, lista: ObjetoGernericoParaSelectModel[]): any;
    abstract avancarUltimo(controle: any, lista: ObjetoGernericoParaSelectModel[]): any;
    abstract desabilitaBotoesAnteriores(id: any, lista: ObjetoGernericoParaSelectModel[]): boolean;
    abstract desabilitaBotoesProximos(id: any, lista: ObjetoGernericoParaSelectModel[]): boolean;
}

@Injectable({ providedIn: 'root' })
export class ControleTrocaEstruturaArmazenagemService implements IControleTrocaEstruturaArmazenagemService {
    public voltarAnterior(controle: any, lista: ObjetoGernericoParaSelectModel[]): any {
        const posicaoAtual = RetornaIndexArrayService.prototype.retornaIndex(controle.value.id, lista);
        if (posicaoAtual > 0) {
            controle.setValue(lista[posicaoAtual - 1]);
            return lista[posicaoAtual - 1].id;
        }
    }

    public voltarPrimeiro(controle: any, lista: ObjetoGernericoParaSelectModel[]): any {
        const posicaoAtual = RetornaIndexArrayService.prototype.retornaIndex(controle.value?.id, lista);
        if (posicaoAtual > 0 || !posicaoAtual) {
            controle.setValue(lista[0]);
            return lista[0].id;
        }
    }

    public avancarProximo(controle: any, lista: ObjetoGernericoParaSelectModel[]): any {
        const posicaoAtual = RetornaIndexArrayService.prototype.retornaIndex(controle.value.id, lista);
        if (posicaoAtual < lista.length - 1) {
            controle.setValue(lista[posicaoAtual + 1]);
            return lista[posicaoAtual + 1].id;
        }
    }

    public avancarUltimo(controle: any, lista: ObjetoGernericoParaSelectModel[]): any {
        const posicaoAtual = RetornaIndexArrayService.prototype.retornaIndex(controle.value.id, lista);
        if (posicaoAtual < lista.length - 1) {
            controle.setValue(lista[lista.length - 1]);
            return lista[lista.length - 1].id;
        }
    }

    public desabilitaBotoesAnteriores(id: any, lista: ObjetoGernericoParaSelectModel[]): boolean {
        const posicaoAtual = RetornaIndexArrayService.prototype.retornaIndex(id, lista);
        if (posicaoAtual === 0) {
            return true;
        }
        if (posicaoAtual > 0) {
            return false;
        }
    }

    public desabilitaBotoesProximos(id: any, lista: ObjetoGernericoParaSelectModel[]): boolean {
        const posicaoAtual = RetornaIndexArrayService.prototype.retornaIndex(id, lista);
        if (posicaoAtual === lista.length - 1) {
            return true;
        }
        if (posicaoAtual < lista.length - 1) {
            return false;
        }
    }
}
