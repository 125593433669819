import { Injectable } from '@angular/core';
import { DiasSemanaEnum } from 'app/modules/armazenagem/cadastro/regras/cadastro/enums';
export abstract class IRetornaDiasSemanaExibicaoService {
    abstract execute(formula: string): string;
}

@Injectable()
export class RetornaDiasSemanaExibicaoService implements IRetornaDiasSemanaExibicaoService {
    public execute(formula: string): string {
        if (formula.includes(DiasSemanaEnum.SEGUNDA_FEIRA)) return 'Segunda-feira';
        if (formula.includes(DiasSemanaEnum.TERCA_FEIRA)) return 'Terça-feira';
        if (formula.includes(DiasSemanaEnum.QUARTA_FEIRA)) return 'Quarta-feira';
        if (formula.includes(DiasSemanaEnum.QUINTA_FEIRA)) return 'Quinta-feira';
        if (formula.includes(DiasSemanaEnum.SEXTA_FEIRA)) return 'Sexta-feira';
        if (formula.includes(DiasSemanaEnum.SABADO)) return 'Sábado';
        if (formula.includes(DiasSemanaEnum.DOMINGO)) return 'Domingo';
    }
}
