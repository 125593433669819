export class PrimeiroAcessoResponseModel {
    ativo: boolean;
    email: string;
    id: number;
    nivel: string;
    nome: string;
    primeiro_acesso: boolean;
    sobrenome: string;
    telefone: string;
    ultima_autenticacao: string;
}
