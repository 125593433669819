import { SiloTipoEnum } from "app/shared/enums/silo.enum";

 export class SiloModel {
  id: number;
  programa_aeracao_id: number;
  nome: string;
  produto_ref: number;
  fabricante_ref: number;
  armazenagem_temporaria: boolean;
  modelo: string;
  notas: string;
  silo_tipo: SiloTipoEnum;
  silo_visualizacao_padrao_ref: number;
}


