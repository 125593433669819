import { Injectable } from '@angular/core';
import { SiloAneisInterface } from 'app/shared/interfaces';
import { configuracaoAneisSilo } from 'app/shared/constants';

export abstract class IDesenhaAneisVisaoSuperiorService {
    abstract execute(aneis: SiloAneisInterface[], diametroSilo: number): any;
}

@Injectable({ providedIn: 'root' })
export class DesenhaAneisVisaoSuperiorService implements IDesenhaAneisVisaoSuperiorService {
    execute(aneis: SiloAneisInterface[], diametroSilo: number): any {
        const canvas: any = document.getElementById('circle');
        const chapeu = document.getElementById('container-chapeu-silo');
        canvas.width = chapeu.offsetWidth;
        canvas.height = chapeu.offsetHeight;
        if (canvas.getContext) {
            aneis.forEach((anel) => {
                const contextoAnel = canvas.getContext('2d');
                const xInicialAnel = canvas.width / 2;
                const yInicialAnel = canvas.height / 2;
                if (anel.raio > 0 && anel.raio < diametroSilo / 2) {
                    const raioAnel = this.converteMetrosParaPixels(anel.raio);
                    contextoAnel.beginPath();
                    contextoAnel.arc(xInicialAnel, yInicialAnel, raioAnel, 0, 2 * Math.PI, false);
                    contextoAnel.lineWidth = configuracaoAneisSilo.width;
                    contextoAnel.strokeStyle = configuracaoAneisSilo.cor;
                    contextoAnel.stroke();
                }
            });
        }
    }

    private converteMetrosParaPixels(raio: number): number {
        return Math.round((raio * configuracaoAneisSilo.pixel) / 100);
    }
}
