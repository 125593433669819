import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { SincronizadorModel } from 'app/shared/models';

export abstract class ISincronizadorController {
    abstract buscaUltimaSincronizacao(): Observable<SincronizadorModel>;
}

@Injectable({
    providedIn: 'root',
})
export class SincronizadorController implements ISincronizadorController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/comunicacao');
    }
    public buscaUltimaSincronizacao(): Observable<SincronizadorModel> {
        return this.transport.http.get<SincronizadorModel>(this.transport.path);
    }
}
