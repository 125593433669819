
import { Injectable } from '@angular/core';

import { EquipamentoTipoEnum } from "app/shared/enums";

export enum InformacoesEquipamentoCategoriaEnum {
  TERMOMETRIA = 'Termometria',
  ESTACAO_METEOROLOGICA = 'Estação meteorológica',
  AUTOMACAO = 'Automação',
  LEITURA_AUXILIAR = 'Leitura auxiliar',
  SECADOR = 'Secador',
}

export abstract class IDefineCategoriaEquipamentoService {
  abstract execute(tipo: EquipamentoTipoEnum): InformacoesEquipamentoCategoriaEnum;
}

@Injectable({ providedIn: 'root' })
export class DefineCategoriaEquipamentoService 
  implements IDefineCategoriaEquipamentoService {

  execute(tipo: EquipamentoTipoEnum): 
    InformacoesEquipamentoCategoriaEnum {
    let categoria = InformacoesEquipamentoCategoriaEnum.LEITURA_AUXILIAR;
   
    if (tipo === EquipamentoTipoEnum.EHD20 || 
        tipo === EquipamentoTipoEnum.PAR12) {
      
      categoria = InformacoesEquipamentoCategoriaEnum.TERMOMETRIA;
    } 

    if (tipo === EquipamentoTipoEnum.WMS) {
      categoria = InformacoesEquipamentoCategoriaEnum.ESTACAO_METEOROLOGICA;
    }
    
    if (tipo === EquipamentoTipoEnum.CONTROL) {
      categoria = InformacoesEquipamentoCategoriaEnum.AUTOMACAO;
    }

    return categoria;
  }
}
