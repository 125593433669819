export class ApiPaginacao {
    sort: string;
    direction: string;
    search: string;
    filter: string;
    limit: number;
    page: number;
    length: number;
    arquivado: boolean;

    constructor(partial?: Partial<ApiPaginacao>) {
        this.page = 0;
        this.limit = 10;
        this.arquivado = false;
        Object.assign(this, partial);
    }
}
