import { StatusAeracaoModel } from 'app/shared/models';

export const configuracoesStatusAeracaoConstant: StatusAeracaoModel[] = [
    {
        codigo: '0A',
        icone: {
            tipo: 'icone-aguardando',
            texto: 'Confirmação de comando',
            classIcone: 'color-[#185BC3]',
            modal: {
                titulo: 'Confirmação de comando',
                subtitulo: 'Aguardando MEP.',
                mensagem: 'Comando enviado. Aguardando confirmação de que foi executado.',
            },
        },
        alerta: {
            cor: '#185BC3',
            background: '#185BC333',
        },
        aerador: {
            cor: 'azul',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '0B',
        icone: {
            tipo: 'icone-aguardando',
            texto: 'Confirmação de comando',
            classIcone: 'color-[#185BC3]',
            modal: {
                titulo: 'Confirmação de comando',
                subtitulo: 'Aguardando MEP.',
                mensagem: 'Comando enviado. Aguardando confirmação de que foi executado.',
            },
        },
        alerta: {
            cor: '#185BC3',
            background: '#185BC333',
        },
        aerador: {
            cor: 'azul-mao-amarela',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '-1',
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Status indefinido',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Status indefinido',
                subtitulo: 'Status indefinido',
                mensagem: 'Status de aeração indefinido.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '1A',
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Falha de comunicação',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Falha de comunicação',
                subtitulo: 'Sem comunicação (relé).',
                mensagem: 'Equipamento responsável pelo acionamento não está comunicando desde {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '1B',
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Falha de comunicação',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Falha de comunicação',
                subtitulo: 'Sem comunicação (relé).',
                mensagem: 'Equipamento responsável pelo acionamento não está comunicando desde {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '2A',
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Falha de comunicação',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Falha de comunicação',
                subtitulo: 'Sem comunicação (chave).',
                mensagem: 'Equipamento responsável pela leitura das chaves não está comunicando desde {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '2B',
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Falha de comunicação',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Falha de comunicação',
                subtitulo: 'Sem comunicação (chave).',
                mensagem: 'Equipamento responsável pela leitura das chaves não está comunicando desde {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '3A',
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Falha de comunicação',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Falha de comunicação',
                subtitulo: 'Sem comunicação (status).',
                mensagem:
                    'Equipamento responsável por informar se o comando foi executado não está comunicando desde {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '3B',
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Falha de comunicação',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Falha de comunicação',
                subtitulo: 'Sem comunicação (status).',
                mensagem:
                    'Equipamento responsável por informar se o comando foi executado não está comunicando desde {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '101',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Painel elétrico ou equipamento Procer',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Painel elétrico ou equipamento Procer',
                subtitulo: 'Aerador não ligou. Verificar disjuntores do painel.',
                mensagem:
                    'O sistema está configurado para acionamento automático e tentou ligar o aerador. As chaves do painel estão na posição automático, porém, o status do motor não está acionado. É possível que o painel elétrico ou equipamento Procer estejam com defeito. Verificar disjuntores do painel.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'preto',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual da aeração',
                mensagem:
                    'Deseja realmente <strong>desligar</strong> a aeração do <strong>{{valor}}</strong>? Ao confirmar, o sistema não fará mais controle automático dos aeradores.',
                botaoAcao: 'desligar',
            },
        },
    },
    {
        codigo: '102',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Painel elétrico ou equipamento Procer',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Painel elétrico ou equipamento Procer',
                subtitulo: 'Aeradores através do sistema estariam desligados.',
                mensagem:
                    'As chaves do painel estão na posição automático e o status do motor está acionado. É possível que o painel elétrico ou equipamento Procer estejam com defeito.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'preto',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual da aeração',
                mensagem:
                    'Deseja realmente <strong>ligar</strong> a aeração do <strong>{{valor}}</strong>? Ao confirmar, o sistema não fará mais controle automático dos aeradores.',
                botaoAcao: 'ligar',
            },
        },
    },
    {
        codigo: '103',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Chave do painel na posição manual',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Chave do painel na posição manual',
                subtitulo: 'Aeradores através do sistema estariam ligados.',
                mensagem:
                    'O sistema está configurado para acionamento automático e tentou ligar o aerador, porém, as chaves do painel estão na posição manual, impossibilitando o motor de ligar ou desligar através do sistema.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '104',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Chave do painel na posição manual',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Chave do painel na posição manual',
                subtitulo: 'Aeradores não serão comandados através do sistema.',
                mensagem:
                    'O sistema está configurado para acionamento automático, o aerador está ligado, porém, as chaves do painel estão na posição manual, impossibilitando o aerador de desligar através do sistema.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '105',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Aerador ligado manualmente',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Aerador ligado manualmente',
                subtitulo: 'Aeradores não serão comandados através do sistema.',
                mensagem:
                    'O sistema está configurado para acionamento automático, porém, as chaves do painel estão na posição manual, impossibilitando o aerador de ligar ou desligar através do sistema.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '106',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Painel elétrico ou equipamento Procer',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Painel elétrico ou equipamento Procer',
                subtitulo: 'Aerador não ligou. Verificar disjuntores do painel.',
                mensagem:
                    'Relé do equipamento Procer está acionado, a chave do painel está na posição automático, porém, o aerador não ligou. Verificar disjuntores do painel.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'preto-mao-amarela',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual da aeração',
                mensagem: 'Deseja realmente <strong>desligar</strong> a aeração do <strong>{{valor}}</strong>?',
                botaoAcao: 'desligar',
            },
        },
    },
    {
        codigo: '107',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Painel elétrico ou equipamento Procer',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Painel elétrico ou equipamento Procer',
                subtitulo: 'Aeradores através do sistema estariam desligados.',
                mensagem:
                    'As chaves do painel estão na posição automático, o status do aerador está acionado e com relé desligado. É possível que o painel elétrico ou equipamento Procer estejam com defeito.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'preto-mao-amarela',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual da aeração',
                mensagem: 'Deseja realmente <strong>ligar</strong> a aeração do <strong>{{valor}}</strong>?',
                botaoAcao: 'ligar',
            },
        },
    },
    {
        codigo: '201',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Chave do painel na posição manual',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Chave do painel na posição manual',
                subtitulo: 'Aeradores não serão comandados através do sistema.',
                mensagem:
                    'O sistema está configurado para acionamento automático, porém, as chaves do painel estão na posição manual, impossibilitando o aerador de ligar ou desligar através do sistema.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'verde-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '202',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Chave do painel na posição manual',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Chave do painel na posição manual',
                subtitulo: 'Aeradores através do sistema estariam ligados.',
                mensagem:
                    'Relé do equipamento Procer está acionado, porém, a chave do painel está na posição manual, impossibilitando o aerador de ligar.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '203',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Chave do painel na posição manual',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Chave do painel na posição manual',
                subtitulo: 'Aeradores não serão comandados através do sistema.',
                mensagem:
                    'Relé do equipamento Procer está acionado, porém, a chave do painel está na posição manual, impossibilitando o aerador de desligar através do sistema.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'verde-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '204',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Aerador ligado manualmente',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Aerador ligado manualmente',
                subtitulo: 'Ligado através do painel.',
                mensagem: 'Aerador ligado manualmente através das botoeiras do painel elétrico',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'verde-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '205',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Chave do painel na posição manual',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Chave do painel na posição manual',
                subtitulo: 'Não é possível comandar os aeradores.',
                mensagem: 'A chave do painel está na posição manual, não será possível comandar o aerador',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'cinza-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '206',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Sistema no programa manual',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Sistema no programa manual',
                subtitulo: 'Aeradores não serão comandados através do sistema.',
                mensagem: 'Aeradores ligados manualmente através do sistema.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'verde-mao-amarela',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual da aeração',
                mensagem: 'Deseja realmente <strong>desligar</strong> a aeração do <strong>{{valor}}</strong>?',
                botaoAcao: 'desligar',
            },
        },
    },
    {
        codigo: '207',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Sistema no programa manual',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Sistema no programa manual',
                subtitulo: 'Aeradores não serão comandados através do sistema.',
                mensagem: 'Sistema no controle manual. Os aeradores não serão comandados automaticamente.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'cinza-mao-amarela',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual da aeração',
                mensagem: 'Deseja realmente <strong>ligar</strong> a aeração do <strong>{{valor}}</strong>?',
                botaoAcao: 'ligar',
            },
        },
    },
    {
        codigo: '401',
        icone: {
            tipo: 'mat_solid:check_circle',
            texto: 'Sistema no programa automático',
            classIcone: 'fill-[#34901C]',
            modal: {
                titulo: 'Sistema no programa automático',
                subtitulo: 'Aeradores configurados para acionamento automático.',
                mensagem: 'Acionamento automático dos aeradores de acordo com o programa estabelecido.',
            },
        },
        alerta: {
            cor: '#34901C',
            background: '#34901C33',
        },
        aerador: {
            cor: 'cinza',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual da aeração',
                mensagem:
                    'Deseja realmente <strong>ligar</strong> a aeração do <strong>{{valor}}</strong>? Ao confirmar, o sistema não fará mais controle automático dos aeradores.',
                botaoAcao: 'ligar',
            },
        },
    },
    {
        codigo: '402',
        icone: {
            tipo: 'mat_solid:check_circle',
            texto: 'Sistema no programa automático',
            classIcone: 'fill-[#34901C]',
            modal: {
                titulo: 'Sistema no programa automático',
                subtitulo: 'Aeradores ligados automaticamente.',
                mensagem: 'Acionamento automático dos aeradores de acordo com o programa configurado.',
            },
        },
        alerta: {
            cor: '#34901C',
            background: '#34901C33',
        },
        aerador: {
            cor: 'verde',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual da aeração',
                mensagem:
                    'Deseja realmente <strong>desligar</strong> a aeração do <strong>{{valor}}</strong>? Ao confirmar, o sistema não fará mais controle automático dos aeradores.',
                botaoAcao: 'desligar',
            },
        },
    },
];
