export enum SucessoApiEnum {
    REGRA_MENSAGEM_ARQUIVARDO_SUCESSO = 'Regra arquivada com sucesso!',
    REGRA_MENSAGEM_DESARQUIVARDO_SUCESSO = 'Regra desarquivada com sucesso!',
    REGRA_MENSAGEM_DUPLICAR_SUCESSO = 'Regra duplicada com sucesso!',
    REGRA_MENSAGEM_EXPORTAR_SUCESSO = 'Regra exportada com sucesso!',
    REGRA_MENSAGEM_SALVAR_SUCESSO = 'Regra salva com sucesso!',
    PROGRAMA_MENSAGEM_SALVAR_SUCESSO = 'Programa salvo com sucesso!',
    PROGRAMA_MENSAGEM_DESARQUIVARDO_SUCESSO = 'Programa desarquivado com sucesso!',
    PROGRAMA_MENSAGEM_ARQUIVARDO_SUCESSO = 'Programa arquivado com sucesso!',
    PROGRAMA_MENSAGEM_DUPLICAR_SUCESSO = 'Programa duplicado com sucesso!',
    PROGRAMA_MENSAGEM_EXPORTAR_SUCESSO = 'Programa exportado com sucesso!',
    GRAO_MENSAGEM_ARQUIVARDO_SUCESSO = 'Grão arquivado com sucesso!',
    GRAO_MENSAGEM_DESARQUIVARDO_SUCESSO = 'Grão desarquivado com sucesso!',
    GRAO_MENSAGEM_SALVAR_SUCESSO = 'Grão salvo com sucesso!',
    COMANDO_ENVIADO_SUCESSO = 'Comando com sucesso!',
    CONTROLE_ALTERADO_SUCESSO = 'Controle da aeração alterado com sucesso!',
    PROGRAMA_ALTERADO_SUCESSO = 'Programa da aeração alterado com sucesso!',
    VARIAVEL_ALTERADA_SUCESSO = 'Variável de aquecimento alterada com sucesso!',
}
