export const configuracaoPendulosSilo = {
    comunicando: {
        background: '#000000',
        texto: '#ffffff',
    },
    semComunicacao: {
        background: '#D4D411',
        texto: '#000000',
    },
};
