import { Injectable } from '@angular/core';
import { configuracoesStatusAeracaoConstant } from '../constants';
import { AeradorStatuSodeEnum } from 'app/shared/enums';
import { StatusAeracaoModel } from 'app/shared//models';

export abstract class IConfigurarStatusAeracaoService {
    abstract execute(statusCodico: AeradorStatuSodeEnum): StatusAeracaoModel;
}

@Injectable({
    providedIn: 'root',
})
export class ConfigurarStatusAeracaoService {
    execute(statusCodico: AeradorStatuSodeEnum): StatusAeracaoModel {
        return configuracoesStatusAeracaoConstant.find((status) => status.codigo === statusCodico);
    }
}
