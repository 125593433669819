import { Injectable } from '@angular/core';
import { TipoEstacaoMeteorologicaEnum } from 'app/shared/enums';
import { EstacaoMeteorologicaInterface } from 'app/shared/interfaces';

export abstract class IOrdenarEstacoesService {
    abstract execute(estacoes: EstacaoMeteorologicaInterface[]): void;
}

@Injectable({
    providedIn: 'root',
})
export class OrdenarEstacoesService {
    constructor() {}

    execute(estacoes: EstacaoMeteorologicaInterface[]): void {
        const local = TipoEstacaoMeteorologicaEnum.LOCAL;

        estacoes.sort((a, b) => {
            switch (true) {
                case a.ultima_leitura.status === true && b.ultima_leitura.status === false:
                    return -1;
                case a.ultima_leitura.status === false && b.ultima_leitura.status === true:
                    return 1;
                case a.ultima_leitura.status === b.ultima_leitura.status:
                    if (a.tipo === local && b.tipo !== local) {
                        return -1;
                    } else if (a.tipo !== local && b.tipo === local) {
                        return 1;
                    } else {
                        return 0;
                    }
                default:
                    return 0;
            }
        });
    }
}
