import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { InformacaoesPaginaObservable } from 'app/shared/observables';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-page-info',
    templateUrl: './page-info.component.html',
    styleUrls: ['./page-info.component.scss'],
})
export class PageInfoComponent implements OnInit, OnDestroy {
    moduleTitle = '';
    pageTitleInfo = '';
    subMenuTitleInfo = '';
    categoriaTitleInfo = '';
    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private pageInfo: InformacaoesPaginaObservable, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.setPageInfo();
    }

    titleCase(str: string): string {
        return str?.toLowerCase().replace(/\b\w/g, (s: any) => s.toUpperCase());
    }

    private setPageInfo(): void {
        this.pageInfo.moduloSelecionado$.pipe(takeUntil(this.unsubscribeAll)).subscribe((module: any) => {
            this.moduleTitle = module;
            this.changeDetectorRef.markForCheck();
        });
        this.pageInfo.subMenuSelecionado$.pipe(takeUntil(this.unsubscribeAll)).subscribe((page: any) => {
            this.pageTitleInfo = page;
            this.changeDetectorRef.markForCheck();
        });
        this.pageInfo.paginaSelecionada$.pipe(takeUntil(this.unsubscribeAll)).subscribe((sub: any) => {
            this.subMenuTitleInfo = sub;
            this.changeDetectorRef.markForCheck();
        });
        this.pageInfo.categoriaSelecionada$.pipe(takeUntil(this.unsubscribeAll)).subscribe((categoria: any) => {
            this.categoriaTitleInfo = categoria;
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
