import { Injectable } from '@angular/core';
import { FormularioModalRetornoModel } from 'app/modules/armazenagem/cadastro/regras/cadastro/models';
import { IRetornaDiasSemanaEnumService } from 'app/shared/services';
export abstract class IDesestruturaStringFormulaParaObjetoService {
    abstract execute(formula: string): any;
}

@Injectable()
export class DesestruturaStringFormulaParaObjetoService implements IDesestruturaStringFormulaParaObjetoService {
    constructor(private retornaDiasSemanaEnumService: IRetornaDiasSemanaEnumService) {}
    public execute(formula: string): any {
        const formulaSemEspacos = formula.replace(/\s/g, '');
        let formulaEditar: FormularioModalRetornoModel = new FormularioModalRetornoModel({});
        const numeroSinalMais = (formulaSemEspacos.match(/\+/g) || []).length;
        const separacaoOperador = formulaSemEspacos.split(this.retornaOperadorUsado(formulaSemEspacos));
        if (numeroSinalMais === 2) {
            const variaveisEsquerda = separacaoOperador[0].split('+');
            const variaveisDireita = separacaoOperador[1].split('+');
            formulaEditar.operador = this.retornaOperadorUsado(formulaSemEspacos);
            formulaEditar.variavelEsquerda = variaveisEsquerda[0].replace(/\T\E\-\T\I/g, 'TE - TI');
            formulaEditar.valorEsquerda = parseFloat(variaveisEsquerda[1]);
            formulaEditar.variavelDireita = variaveisDireita[0].replace(/\T\E\-\T\I/g, 'TE - TI');
            formulaEditar.valorDireita = parseFloat(variaveisDireita[1]);
            return formulaEditar;
        }
        if (formulaSemEspacos.includes('HRSLIGAR')) {
            const horasLigar = formulaSemEspacos.replace('HRSLIGAR', 'HRSLIGAR#').split('#');
            formulaEditar.variavelEsquerda = horasLigar[0];
            formulaEditar.horarioInicial = horasLigar[1].split('-')[0].replace(/\[/, '');
            formulaEditar.horarioFinal = horasLigar[1].split('-')[1].replace(/\]/, '');
            return formulaEditar;
        }
        if (formulaSemEspacos.includes('HRSPROIBALL') || formulaSemEspacos.includes('HRSPROIBALL')) {
            formulaEditar.variavelEsquerda = formulaSemEspacos;
            return formulaEditar;
        }
        if (!this.retornaOperadorUsado(formulaSemEspacos) && !formulaSemEspacos.includes('DIASEMANA')) {
            formulaEditar.variavelEsquerda = formulaSemEspacos;
            return formulaEditar;
        }
        if (formulaSemEspacos.includes('DIASEMANA')) {
            formulaEditar.variavelEsquerda = 'DIASEMANA';
            formulaEditar.diaSemana = this.retornaDiasSemanaEnumService.execute(formulaSemEspacos);
            return formulaEditar;
        }
        if (formulaSemEspacos.includes('QTDESACOSPROD')) {
            formulaEditar.variavelEsquerda = 'QTDESACOSPROD';
            formulaEditar.operador = this.retornaOperadorUsado(formulaSemEspacos);
            formulaEditar.valorDireita = parseFloat(separacaoOperador[1]);
            return formulaEditar;
        }
        if (this.retornaOperadorUsado(formulaSemEspacos)) {
            const variaveisEsquerda = separacaoOperador[0].split('+');
            const variaveisDireita = separacaoOperador[1].split('+');
            formulaEditar.operador = this.retornaOperadorUsado(formulaSemEspacos);
            if (variaveisEsquerda.length > 1) {
                formulaEditar.variavelEsquerda = variaveisEsquerda[0].replace(/\T\E\-\T\I/g, 'TE - TI');
                formulaEditar.valorEsquerda = parseFloat(variaveisEsquerda[1]);
            } else {
                formulaEditar.variavelEsquerda = variaveisEsquerda[0].replace(/\T\E\-\T\I/g, 'TE - TI');
            }
            if (variaveisDireita.length > 1) {
                formulaEditar.variavelDireita = variaveisDireita[0].replace(/\T\E\-\T\I/g, 'TE - TI');
                formulaEditar.valorDireita = parseFloat(variaveisDireita[1]);
            } else {
                if (isNaN(parseFloat(variaveisDireita[0]))) {
                    formulaEditar.variavelDireita = variaveisDireita[0].replace(/\T\E\-\T\I/g, 'TE - TI');
                } else {
                    formulaEditar.valorDireita = parseFloat(variaveisDireita[0]);
                }
            }
            return formulaEditar;
        }
    }
    private retornaOperadorUsado(formula: string): string {
        if (formula.includes('>=')) return '>=';
        if (formula.includes('<=')) return '<=';
        if (formula.includes('==')) return '==';
        if (formula.includes('!=')) return '!=';
        if (formula.includes('>')) return '>';
        if (formula.includes('<')) return '<';
    }
}
