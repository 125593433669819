export interface PenduloInterface {
    id: number;
    nome: string;
    sequencia: number;
    nivelSensor: {
        nivel: number;
        instante: string;
    };
    penduloCanal: {
        id: number;
        canal_temperatura: number;
        canal_umidade: number;
        posicao_sensor_umidade: number[];
    };
    penduloPropriedade: {
        id: number;
        digitalTermopar: string;
        quantidadeSensoresTemperatura: number;
    };
    equipamento: {
        id: number;
        nome: string;
        status: string;
    };
    ultimaLeituraUmidadeSensores: {
        ativo: boolean;
        instante: any;
        umidade: any;
    };
    ultimaLeituraTemperaturaSensores: {
        ativo: boolean;
        instante: string;
        instantePendulo: string;
        temperatura: number[];
    };
}
