import { BehaviorSubject, Observable } from 'rxjs';

export class InformacaoesPaginaObservable {
    moduloSelecionado: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    paginaSelecionada: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    subMenuSelecionado: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    categoriaSelecionada: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    subMenuSelecionadoModal: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    menuSelecionadoModal: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

    get moduloSelecionado$(): Observable<any> {
        return this.moduloSelecionado.asObservable();
    }
    get paginaSelecionada$(): Observable<any> {
        return this.paginaSelecionada.asObservable();
    }
    get subMenuSelecionado$(): Observable<any> {
        return this.subMenuSelecionado.asObservable();
    }
    get categoriaSelecionada$(): Observable<any> {
        return this.categoriaSelecionada.asObservable();
    }

    get menuSelecionadoModal$(): Observable<any> {
        return this.menuSelecionadoModal.asObservable();
    }
    get subMenuSelecionadoModal$(): Observable<any> {
        return this.subMenuSelecionadoModal.asObservable();
    }

    set setaModuloSelecionado(value: any) {
        this.moduloSelecionado.next(value);
    }
    set setaPaginaSelecionada(value: any) {
        this.paginaSelecionada.next(value);
    }
    set setaSubMenuSelecionado(value: any) {
        this.subMenuSelecionado.next(value);
    }
    set setaCategoriaSelecionada(value: any) {
        this.categoriaSelecionada.next(value);
    }

    set setaMenuSelecionadoModal(value: any) {
        this.menuSelecionadoModal.next(value);
    }
    set setaSubMenuSelecionadoModal(value: any) {
        this.subMenuSelecionadoModal.next(value);
    }
}
