import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export abstract class IFirstAccessObservable {
    abstract setFirstAccessObservable(primeiroAcesso: boolean): void;
    abstract getFirstAccessObservable(): Observable<boolean>;
}
@Injectable({
    providedIn: 'root',
})
export class FirstAccessObservable implements IFirstAccessObservable {
    private primeiroAcessoObservable = new BehaviorSubject<boolean>(false);

    setFirstAccessObservable(primeiroAcesso: boolean): void {
        this.primeiroAcessoObservable.next(primeiroAcesso);
    }

    getFirstAccessObservable(): Observable<boolean> {
        return this.primeiroAcessoObservable.asObservable();
    }
}
