import { TipoAmbiente } from 'app/shared/enums';

export const environment = {
    production: true,
    isMockEnabled: false,
    level: TipoAmbiente.NUVEM,
    api: {
    	url: "https://api.ceres.procer.com.br/v1"
    },
    recaptcha: {
        siteKey: '6Lc5m28dAAAAAOIc0npFhcBAWjoiYeX07o0HPVau',
    },
    whiteLabel: {
        logoLoginUrl: 'assets/images/logo/logo-procer-azul.svg',
        logoRedirecionamentoUrl: 'assets/images/logo/logo-procer.png',
        logoMenuFechadoUrl: 'assets/images/logo/logo-menu.png',
        logoMenuAbertoUrl: 'assets/images/logo/logo-navbar.png',
        imgMapaContatos: 'assets/img/mapa-contatos-procer.gif',
    },
};
