import { Injectable } from '@angular/core';

export abstract class IObjectSubmitService {
  abstract emptyFieldToNull(object: any): any;
}

@Injectable({ providedIn: 'root' })
export class ObjectSubmitService implements IObjectSubmitService {

  public emptyFieldToNull(object: any): any {
    Object.entries(object).forEach(([key, value]) => {
      if (object[key] === '' || object[key] == undefined) {
        delete object[key];
      }
    });

    return object;
  }
}
