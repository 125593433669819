export class RegraModel {
    id?: number;
    nome: string;
    arquivado: boolean;
    vinculado?: boolean;
    expressao_ligar?: string;
    expressao_manter_ligado?: string;
    ultima_alteracao?: string;
    notas: string;
    programa_aeracao: any[];

    constructor(partial?: Partial<RegraModel>) {
        Object.assign(this, partial);
    }
}
