export const configuracaoTamanhoModal = {
    informativo: {
        desktop: {
            width: '547px',
            hegth: '416px',
        },
        mobile: {
            width: '100%',
            hegth: 'auto',
        },
    },
    comTabela: {
        desktop: {
            width: '650px',
            hegth: '416px',
        },
        mobile: {
            width: '100%',
            hegth: 'auto',
        },
    },
};
