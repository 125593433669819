<div class="w-48 h-9 bg-[#F4F4F4] flex justify-center items-center mx-auto rounded">
    <button
        mat-icon-button
        matTooltip="Clique para ver o primeiro registro"
        (click)="voltarPrimeiroHistoricoSilo()"
        class="bg-[#F4F4F4] h-9 w-10 rounded-l rounded-r-none border-[#E5E5E5] bottom-[0.34px] flex items-center justify-center disabled:opacity-75"
        matTooltipClass="tooltip-padrao"
        [disabled]="playStartado || desabilitaBotoesAnteriores()"
    >
        <mat-icon [svgIcon]="'icon-voltar-todos'"> </mat-icon>
    </button>
    <button
        mat-icon-button
        matTooltip="Clique para ver o registro anterior"
        (click)="voltarHistoricoSiloanterior()"
        class="bg-[#F4F4F4] h-9 w-10 rounded-none border-[#E5E5E5] bottom-[0.34px] flex items-center justify-center disabled:opacity-75"
        matTooltipClass="tooltip-padrao"
        [disabled]="playStartado || desabilitaBotoesAnteriores()"
    >
        <mat-icon [svgIcon]="'icon-voltar-um'"> </mat-icon>
    </button>
    <button
        mat-icon-button
        matTooltip="Clique para iniciar a visualização de todos os registros"
        (click)="playHistorico()"
        class="bg-[#F4F4F4] h-9 w-10 rounded-none border-[#E5E5E5] bottom-[0.34px] flex items-center justify-center disabled:opacity-75"
        matTooltipClass="tooltip-padrao"
        [disabled]="!permiteRotarPlay || desabilitaBotoesProximos()"
    >
        <mat-icon *ngIf="!playStartado" [svgIcon]="'icon-play'"> </mat-icon>
        <mat-icon *ngIf="playStartado" [svgIcon]="'icon-pausa'"> </mat-icon>
    </button>
    <button
        mat-icon-button
        matTooltip="Clique para ver o próximo registro"
        (click)="avancarProximoHistoricoSilo()"
        class="bg-[#F4F4F4] h-9 w-10 rounded-none border-[#E5E5E5] bottom-[0.34px] flex items-center justify-center disabled:opacity-75"
        matTooltipClass="tooltip-padrao"
        [disabled]="playStartado || desabilitaBotoesProximos()"
    >
        <mat-icon [svgIcon]="'icon-avancar-um'"> </mat-icon>
    </button>
    <button
        mat-icon-button
        matTooltip="Clique para ver o último registro"
        (click)="avancarUltimoHistoricoSilo()"
        class="bg-[#F4F4F4] h-9 w-10 rounded-r rounded-l-none border-[#E5E5E5] bottom-[0.34px] flex items-center justify-center disabled:opacity-75"
        matTooltipClass="tooltip-padrao"
        [disabled]="playStartado || desabilitaBotoesProximos()"
    >
        <mat-icon [svgIcon]="'icon-avancar-todos'"> </mat-icon>
    </button>
</div>
