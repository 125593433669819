import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { AsideMenuObservable } from 'app/shared/observables';
import { IAlertaService } from 'app/shared/services';
import { TipoDeAlerta } from 'app/shared/models';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { ClassicService } from './service';

@Component({
    selector: 'classic-layout',
    templateUrl: './classic.component.html',
    styleUrls: ['./classic.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    alerta: TipoDeAlerta = new TipoDeAlerta({
        titulo: '',
        mostrar: false,
    });
    testEnviromentMode = false;
    isMenuShow = false;
    recarregarComponente = false;
    logoUrl: string = environment.whiteLabel.logoLoginUrl;

    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _changeDetectorRef: ChangeDetectorRef,
        private asideMenuObservable: AsideMenuObservable,
        private alertaService: IAlertaService,
        readonly classicService: ClassicService
    ) {}

    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        this.alertaService.alerta$.pipe(takeUntil(this.unsubscribeAll)).subscribe((alerta) => {
            this.alerta = alerta;
        });

        // Subscribe to navigation data
        this._navigationService.navigation$.pipe(takeUntil(this.unsubscribeAll)).subscribe((navigation: Navigation) => {
            this.navigation = navigation;
        });

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.isScreenSmall = !matchingAliases.includes('md');
            });
        this.asideMenuObservable.menuMinimizadoSubject.pipe(takeUntil(this.unsubscribeAll)).subscribe((hide) => {
            this.isMenuShow = !hide;
            this._changeDetectorRef.detectChanges();
        });
        this.testEnviromentMode = !environment.production;
    }

    habilitaSicronizador(): boolean {
        const menuSelecionado = JSON.parse(localStorage.getItem('ultimoMenuSelecioandoTitulo'));
        if (menuSelecionado?.link.includes('secador')) {
            return false;
        } else {
            return true;
        }
    }

    trocaUnidade(e: any): void {
        let temporizador;
        window.clearTimeout(temporizador);
        if (e) {
            this.recarregarComponente = e;
        }
        temporizador = setTimeout(() => {
            this.recarregarComponente = false;
        }, 100);
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }

    toggleNavigation(name: string): void {
        if (!this.isMenuShow) {
            this.asideMenuObservable.menuMinimizadoSubject.next(false);
            this.classicService.abrirMenuSobreposto();
        } else {
            this.asideMenuObservable.menuMinimizadoSubject.next(true);
            this.classicService.fecharMenuSobreposto();
        }
    }
}
