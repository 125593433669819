import { BlocoFormulasModel } from 'app/modules/armazenagem/cadastro/regras/cadastro/models';

export class ArvoreFormulasModel {
    formula?: string;
    blocos?: BlocoFormulasModel[];

    constructor(partial?: Partial<ArvoreFormulasModel>) {
        Object.assign(this, partial);
    }
}
