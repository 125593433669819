export class ProgramaModel {
    id?: number;
    nome: string;
    condicao_ligar?: string;
    condicao_manter_ligado?: string;
    objetivo_programa: string;
    ultima_alteracao?: string;
    notas: string;
    arquivado: boolean;
    silos: any[];

    constructor(partial?: Partial<ProgramaModel>) {
        Object.assign(this, partial);
    }
}
