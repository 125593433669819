import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UnidadeInterface } from 'app/shared/interfaces';

@Injectable({
    providedIn: 'root',
})
export class UnidadeParametrizacaoObservable {
    private unidadeParametrizacao: BehaviorSubject<any | null> = new BehaviorSubject(null);

    set setParametrosUnidade(value: UnidadeInterface) {
        this.unidadeParametrizacao.next(value);
    }

    get buscaParametrosUnidade$(): Observable<UnidadeInterface> {
        return this.unidadeParametrizacao.asObservable();
    }
}
