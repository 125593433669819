import { EquipamentoModel } from "app/shared/models";

export class EstacaoMeteorologicaModel {
    nome: string;
    tipo: string;
    chuva: any;
    chovendo: any;
    temperatura: string;
    umidade: string;
    status_coletor: boolean;
    status_equipamento: boolean;
    equipamento: EquipamentoModel;
}
