import { ObjetivoProgramaEnum } from 'app/shared/enums';

export class ProgramaResponseModel {
    id: number;
    ultima_alteracao: string;
    arquivado: boolean;
    nome: string;
    objetivo_programa: ObjetivoProgramaEnum;
    expressao: string;
    notas: string;

    constructor(partial?: Partial<ProgramaResponseModel>) {
        Object.assign(this, partial);
    }
}
