export class EquilibrioHigroscopicoModel {
    constructor(partial?: Partial<EquilibrioHigroscopicoModel>) {
        Object.assign(this, partial);
    }
    aq: number;
    taq: number;
    uraq: number;
    ehaq: number;
    ehamb: number;
    nome?: string;
    ehplenum?: number;
}
