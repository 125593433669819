import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { VariaveisControleEnum } from 'app/modules/armazenagem/cadastro/regras/cadastro/enums';
import { IRetornaDiasSemanaExibicaoService } from 'app/shared/services';
import { IDesestruturaStringFormulaParaObjetoService } from 'app/shared/services';

export abstract class IDesestruturaStringFormulaListagemService {
    abstract execute(formula: string, parametrosUnidade): any;
}

@Injectable()
export class DesestruturaStringFormulaListagemService implements IDesestruturaStringFormulaListagemService {
    constructor(
        private desestruturaStringFormulaParaObjetoService: IDesestruturaStringFormulaParaObjetoService,
        private retornaDiasSemanaExibicaoService: IRetornaDiasSemanaExibicaoService,
        private decimalPipe: DecimalPipe
    ) {}
    public execute(formulas: string, parametrosUnidade): any {
        const formulaSemEspacos = formulas.replace(/\s/g, '');
        if (formulaSemEspacos.includes('||') && formulaSemEspacos.includes('&&')) {
            return this.tratamentoBlocoOuComBlocoE(
                formulaSemEspacos.replace(/\(/g, '').replace(/\)/g, ''),
                parametrosUnidade
            );
        }
        if (!formulaSemEspacos.includes('||') && formulaSemEspacos.includes('&&')) {
            return this.tratamentoBlocoE(formulaSemEspacos, parametrosUnidade);
        }
        if (formulaSemEspacos.includes('||') && !formulaSemEspacos.includes('&&')) {
            return this.tratamentoBlocoOu(formulaSemEspacos.replace(/\(/g, '').replace(/\)/g, ''), parametrosUnidade);
        }
        if (!formulaSemEspacos.includes('||') && !formulaSemEspacos.includes('&&')) {
            return `(${this.formataFormulaApenasParaVisualizacao(formulaSemEspacos, parametrosUnidade).replace(
                /\s{2,}/g,
                ' '
            )})`;
        }
    }

    private formataFormulaApenasParaVisualizacao(formula, parametrosUnidade): any {
        const formulaDesestruturada = this.desestruturaStringFormulaParaObjetoService.execute(formula);
        return `${formulaDesestruturada.variavelEsquerda} ${formulaDesestruturada.valorEsquerda ? ' + ' : ''}${
            formulaDesestruturada.valorEsquerda
                ? this.decimalPipe.transform(formulaDesestruturada.valorEsquerda, '1.2-2')
                : ''
        } ${this.substituiSimboloPorDescricaoOperador(formulaDesestruturada.operador)} ${
            formulaDesestruturada.variavelDireita ?? ''
        } ${formulaDesestruturada.variavelDireita && formulaDesestruturada.valorDireita ? ' + ' : ''} ${
            formulaDesestruturada.valorDireita
                ? this.decimalPipe.transform(formulaDesestruturada.valorDireita, '1.2-2')
                : ''
        } ${formulaDesestruturada.valorDireita === 0 ? '0' : ''} ${
            formulaDesestruturada.diaSemana
                ? '[' + this.retornaDiasSemanaExibicaoService.execute(formulaDesestruturada.diaSemana) + ']'
                : ''
        } ${
            formulaDesestruturada.horarioInicial && formulaDesestruturada.horarioFinal
                ? formulaDesestruturada.horarioInicial + ' a ' + formulaDesestruturada.horarioFinal
                : ''
        }${
            parametrosUnidade &&
            (formulaDesestruturada.variavelEsquerda === VariaveisControleEnum.HRSPROIB ||
                formulaDesestruturada.variavelEsquerda === VariaveisControleEnum.HRSPROIBALL)
                ? parametrosUnidade?.horario_pico_inicio.substring(0, 5) +
                  ' a ' +
                  parametrosUnidade?.horario_pico_fim.substring(0, 5)
                : ''
        }`;
    }

    private substituiSimboloPorDescricaoOperador(operador: string) {
        if (operador?.includes('>=')) return ' maior ou igual ';
        if (operador?.includes('<=')) return ' menor ou igual ';
        if (operador?.includes('!=')) return ' diferente ';
        if (operador?.includes('==')) return ' igual ';
        if (operador?.includes('>')) return ' maior que ';
        if (operador?.includes('<')) return ' menor que ';
        return operador ?? '';
    }

    private tratamentoBlocoOuComBlocoE(formula, parametrosUnidade): string {
        const blocoPai = formula.split('||');
        const BlocoOU = [];
        const BlocoE = [];
        blocoPai.forEach((formula) => {
            if (!formula.includes('&&')) {
                const formulaDesestruturada = this.formataFormulaApenasParaVisualizacao(formula, parametrosUnidade);
                BlocoOU.push(`(${formulaDesestruturada})`);
            } else {
                const blocosFilho = formula.split('&&');
                blocosFilho.forEach((formula, index) => {
                    const formulaDesestruturada = this.formataFormulaApenasParaVisualizacao(formula, parametrosUnidade);
                    BlocoE.push(`(${formulaDesestruturada})`);
                });
            }
        });
        return `(${BlocoOU.join(' OU ').replace(/\s{2,}/g, ' ')}) OU (${BlocoE.join(' E ').replace(/\s{2,}/g, ' ')})`;
    }

    private tratamentoBlocoOu(formula, parametrosUnidade): string {
        const blocoPai = formula.split('||');
        const BlocoOU = [];
        blocoPai.forEach((formula) => {
            if (!formula.includes('&&')) {
                const formulaDesestruturada = this.formataFormulaApenasParaVisualizacao(formula, parametrosUnidade);
                BlocoOU.push(`(${formulaDesestruturada})`);
            }
        });
        return `(${BlocoOU.join(' OU ').replace(/\s{2,}/g, ' ')})`;
    }

    private tratamentoBlocoE(formula, parametrosUnidade): string {
        const blocoPaiGeral = formula.split('&&((');
        const blocoPaiFormulas = blocoPaiGeral[0].split('&&');
        const blocoEPai = [];
        const blocoEFilhos = [];
        const blocoEFilhosApresentacao = [];
        blocoPaiFormulas.forEach((formula, index) => {
            const formulaDesestruturada = this.formataFormulaApenasParaVisualizacao(
                formula.replace(/\(/g, '').replace(/\)/g, ''),
                parametrosUnidade
            );
            blocoEPai.push(`(${formulaDesestruturada})`);
        });
        blocoPaiGeral.forEach((formula, index) => {
            if (index !== 0) {
                blocoEFilhos.push(formula.split('&&'));
            }
        });
        blocoEFilhos.forEach((bloco) => {
            const formulasComParenteses = bloco.map((formula) => {
                const formulaDesestruturada = this.formataFormulaApenasParaVisualizacao(formula, parametrosUnidade);
                return (formula = `(${formulaDesestruturada.replace(/\(/g, '').replace(/\)/g, '')})`);
            });
            blocoEFilhosApresentacao.push(formulasComParenteses.join(' E '));
        });
        if (blocoEFilhosApresentacao.length > 0) {
            return `(${blocoEPai.join(' E ').replace(/\s{2,}/g, ' ')}) E (${blocoEFilhosApresentacao
                .join(' E ')
                .replace(/\s{2,}/g, ' ')})`;
        } else {
            return `(${blocoEPai.join(' E ').replace(/\s{2,}/g, ' ')})`;
        }
    }
}
