import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from 'app/shared/shared.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HeaderSelectCustomerComponent } from './header-select-customer.component';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    declarations: [HeaderSelectCustomerComponent],
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatTooltipModule,
        MatMenuModule,
        SharedModule,
        TranslocoModule,
        NgxMatSelectSearchModule,
        MatSelectModule,
        ReactiveFormsModule,
        CommonModule,
        TranslocoRootModule,
    ],
    exports: [HeaderSelectCustomerComponent],
})
export class HeaderSelectCustomerModule {}
