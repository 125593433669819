import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { SiloBaseEnum } from 'app/shared/enums';
import { AeradorInterface, InformacoesSiloInterface } from 'app/shared/interfaces';

declare let $: any;
declare let PlainSiloView: any;

@Component({
    selector: 'app-visao-plana-silo',
    templateUrl: './visao-plana-silo.component.html',
    styleUrls: ['./visao-plana-silo.component.scss'],
})
export class VisaoPlanaSiloComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('selector') selector: ElementRef;

    @Input() silo: InformacoesSiloInterface;
    @Input() config: string;
    @Input() level: string;
    @Input() thermal: string;
    @Input() humidity: string;
    @Input() emc: string;
    @Input() thermocouples: string;
    @Input() selected: number;
    @Input() communicatingCables: string;
    @Input() communicatingInfo: any;
    @Input() historical?: boolean;
    @Input() siloViewSiloOptions: any;
    @Input() alias: string;
    @Input() cables?: [];
    @Input() historicalDate?: Date;
    @Input() configWidthDivTime?: string;
    @Input() viewTimeSilo: boolean;
    @Input() exibirTooltip: boolean = true;
    @Input() exibirAeradores?: boolean = false;
    @Input() possuiAeracaoAutomatica?: boolean = false;
    listaAeradores: AeradorInterface[] = [];

    element: any;
    instance: any;

    constructor(private zone: NgZone, private _changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (this.silo?.aeradores?.length > 0) {
            this.listaAeradores = this.silo.aeradores;
        }
    }

    ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => this.initPlugin());
        this._changeDetectorRef.detectChanges();
    }

    private initPlugin(): void {
        this.element = $(this.selector.nativeElement);
        this.setViewOptions(this.element);
        this.instance = new PlainSiloView(this.element);
    }

    private setViewOptions(_element: any): void {
        this.podeDispararEventoCustomizadoAeradores(_element);
        if (this.siloViewSiloOptions) {
            if (this.siloViewSiloOptions === SiloBaseEnum.EM_V) {
                _element.attr('data-cone-bottom', true);
                const sensoresTelhado = this.adequaEspacamentoSensores().split(';');
                if (!sensoresTelhado.every((valor) => valor === '0')) {
                    _element.attr('data-y-offset', this.colocaSensoresMesmaLinha());
                    _element.attr('data-roof-sensors', this.adequaEspacamentoSensores());
                }
            }
            if (this.siloViewSiloOptions === SiloBaseEnum.PLANA) {
                _element.attr('data-large-roof-window', true);
                const sensoresTelhado = this.adequaEspacamentoSensores().split(';');
                if (!sensoresTelhado.every((valor) => valor === '0')) {
                    _element.attr('data-roof-sensors', this.adequaEspacamentoSensores());
                }
            }

            if (this.siloViewSiloOptions === SiloBaseEnum.SEMI_V) {
                _element.attr('data-semicone-bottom', true);
                _element.attr('data-y-offset', this.colocaSensoresMesmaLinha());
                _element.attr('data-roof-sensors', this.adequaEspacamentoSensores());
            }
        }
    }

    private adequaEspacamentoSensores(): string {
        let min = 0;
        let max = 0;
        let numeroSensoresArrayInt = [];
        let arraySensoresTelhado = [];
        const numeroSensoresArrayString = this.config.split(';');
        numeroSensoresArrayString.forEach((sensor) => {
            numeroSensoresArrayInt.push(parseInt(sensor));
        });
        min = Math.min(...numeroSensoresArrayInt);
        max = Math.max(...numeroSensoresArrayInt);
        numeroSensoresArrayInt.forEach((sensor) => {
            if (sensor > min) {
                arraySensoresTelhado.push(sensor - min);
            } else {
                arraySensoresTelhado.push(0);
            }
        });
        return arraySensoresTelhado.join(';');
    }

    private colocaSensoresMesmaLinha(): string {
        let arrayNumeroPendulos = [];
        const numeroSensoresArrayString = this.config.split(';');
        numeroSensoresArrayString.forEach(() => {
            arrayNumeroPendulos.push(1);
        });
        return arrayNumeroPendulos.join(';');
    }

    private podeDispararEventoCustomizadoAeradores(_element): void {
        if (this.exibirAeradores) {
            _element.attr('data-exibir-aeradores', true);
        } else {
            _element.attr('data-exibir-aeradores', false);
        }
    }

    ngOnDestroy(): void {
        this.element.attr('data-exibir-aeradores', false);
    }
}
