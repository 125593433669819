<div class="w-full justify-center flex md:visible invisible">
    <div
        id="aerador1"
        class="w-50 h-28 absolute z-10 flex justify-start bg-no-repeat scale-x-[-1]"
        [style]="{
            'margin-top': aeradores[0]?.y,
            'margin-left': aeradores[0]?.x,
            'background-image': aeradores[0]?.imagem
                ? 'url(' + './assets/img/aeradores/' + aeradores[0]?.imagem + ')'
                : ''
        }"
    ></div>
    <div
        *ngIf="this.listaAeradores.length >= 2"
        id="aerador2"
        class="w-50 h-28 absolute z-10 flex justify-end bg-no-repeat"
        [style]="{
            'margin-top': aeradores[1]?.y,
            'margin-right': aeradores[1]?.x,
            'background-image': aeradores[1]?.imagem
                ? 'url(' + './assets/img/aeradores/' + aeradores[1]?.imagem + ')'
                : ''
        }"
    ></div>
    <div
        *ngIf="this.listaAeradores.length >= 3"
        id="aerador3"
        class="w-50 h-28 absolute z-9 flex justify-start bg-no-repeat scale-x-[-1]"
        [style]="{
            'margin-top': aeradores[2]?.y,
            'margin-left': aeradores[2]?.x,
            'background-image': aeradores[2]?.imagem
                ? 'url(' + './assets/img/aeradores/' + aeradores[2]?.imagem + ')'
                : ''
        }"
    ></div>
    <div
        *ngIf="this.listaAeradores.length >= 4"
        id="aerador4"
        class="w-50 h-28 absolute z-9 flex justify-end bg-no-repeat"
        [style]="{
            'margin-top': aeradores[3]?.y,
            'margin-right': aeradores[3]?.x,
            'background-image': aeradores[3]?.imagem
                ? 'url(' + './assets/img/aeradores/' + aeradores[3]?.imagem + ')'
                : ''
        }"
    ></div>
</div>
