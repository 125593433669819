export enum HistoricoComandoAeracaoColunasEnum {
    INCLUSAO = 'inclusao',
    ATUALIZACAO = 'atualizacao',
    TIPO = 'tipo',
    ESTAGIO = 'estagio',
    OPERACAO = 'operacao',
    USUARIO = 'usuario',
    RESULTADO = 'resultado',
    DETALHES = 'detalhes',
}
