import { Injectable } from '@angular/core';
import { ObjetivoProgramaEnum } from 'app/shared/enums';
export abstract class IRetornaObjetivoProgramaAeracaoService {
    abstract execute(objeto: ObjetivoProgramaEnum): string;
}

@Injectable({ providedIn: 'root' })
export class RetornaObjetivoProgramaAeracaoService implements IRetornaObjetivoProgramaAeracaoService {
    public execute(objeto: any): string {
        let nome = null;
        switch (objeto) {
            case ObjetivoProgramaEnum.CONSERVACAO: {
                nome = 'Conservação';
                break;
            }
            case ObjetivoProgramaEnum.CORRETIVA: {
                nome = 'Corretiva';
                break;
            }
            case ObjetivoProgramaEnum.RESFRIAMENTO: {
                nome = 'Resfriamento';
                break;
            }
            case ObjetivoProgramaEnum.RESFRIAMENTO_E_CONSERVACAO: {
                nome = 'Resfriamento e conservação';
                break;
            }
            case ObjetivoProgramaEnum.SECAGEM: {
                nome = 'Secagem';
                break;
            }
        }
        return nome;
    }
}
