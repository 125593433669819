import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable, take } from 'rxjs';
import { FirstAccessObservable } from 'app/modules/auth/observables';
@Injectable({ providedIn: 'root' })
export class CanUseSystemGuard implements CanActivate, CanActivateChild {
    constructor(private readonly router: Router, private firstAccessObservable$: FirstAccessObservable) {}

    public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((res) => {
            this.firstAccessObservable$
                .getFirstAccessObservable()
                .pipe(take(1))
                .subscribe((acesso) => {
                    if (!acesso) {
                        this.router.navigate(['/first-access']);
                    } else {
                        res(true);
                    }
                });
        });
    }

    public canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate();
    }
}
