import { Menu } from 'app/core/aside-menu/models/menu.model';
import { BehaviorSubject } from 'rxjs';

export class AsideMenuObservable {
    public modulosSubject = new BehaviorSubject<Menu[] | undefined>([]);
    public menusSubject = new BehaviorSubject<Menu[] | undefined>([]);
    public menuMinimizadoSubject = new BehaviorSubject<boolean>(true);
    public moduloSelecionadoSubject = new BehaviorSubject<Menu | undefined>(
        undefined
    );
}
