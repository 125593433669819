import { Injectable } from '@angular/core';
import { graficoTraducao } from 'app/shared/constants';
import { GraficoOpcoes } from 'app/shared/interfaces';
import { FormataGraficoInterface } from 'app/modules/armazenagem/analise/graficos/unidade-armazenadora/interfaces';
export abstract class IDefineGraficoBarrasService {
    abstract execute(series: FormataGraficoInterface[], categorias: string[]): Partial<GraficoOpcoes>;
}

@Injectable({ providedIn: 'root' })
export class DefineGraficoBarrasService implements IDefineGraficoBarrasService {
    constructor() {}

    execute(series: FormataGraficoInterface[], categorias: string[]): Partial<GraficoOpcoes> {
        return {
            chart: {
                height: 350,
                type: 'bar',
                stacked: false,
                fontFamily: 'obvia-wide-regular',
                defaultLocale: 'pt-br',
                locales: [graficoTraducao],
                zoom: {
                    enabled: true,
                },
                toolbar: {
                    show: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 1,
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 7,
                },
            },
            grid: {
                borderColor: '#f1f1f1',
            },
            series: series,
            xaxis: {
                labels: {
                    trim: false,
                    showDuplicates: false,
                },
                categories: categorias,
                tickPlacement: 'on',
            },
            legend: {
                markers: {
                    radius: 12,
                },
            },
            tooltip: {
                y: {
                    title: {
                        formatter: function (val: any) {
                            return val + ' (h)';
                        },
                    },
                },
            },
        } as Partial<GraficoOpcoes>;
    }
}
