import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageInfoComponent } from './page-info.component';
import { InformacaoesPaginaObservable } from 'app/shared/observables';

@NgModule({
    declarations: [PageInfoComponent],
    imports: [CommonModule],
    exports: [PageInfoComponent],
    providers: [InformacaoesPaginaObservable],
})
export class PageInfoModule {}
