import { Injectable } from '@angular/core';
export abstract class IControleAcoesHistoricoService {
    abstract voltarPosicaoAnterior(indexAtual: number, array: any[]): any;
    abstract voltarPrimeiraPosicao(indexAtual: number, array: any[]): any;
    abstract avancarProximaPosicao(indexAtual: number, array: any[]): any;
    abstract avancarUltimaPosicao(indexAtual: number, array: any[]): any;
    abstract desabilitaBotoesAnteriores(indexAtual: number, array: any[]): boolean;
    abstract desabilitaBotoesProximos(indexAtual: number, array: any[]): boolean;
    abstract desabilitaBotaoPlay(indexAtual: number, array: any[]): boolean;
    abstract retornaIndex(instante: any, array: any[]): number;
}

@Injectable({ providedIn: 'root' })
export class ControleAcoesHistoricoService implements IControleAcoesHistoricoService {
    public voltarPosicaoAnterior(indexAtual: number, array: any[]): any {
        const posicaoAtual = this.retornaIndex(indexAtual, array);
        if (posicaoAtual > 0) {
            return array[posicaoAtual - 1];
        }
    }

    public voltarPrimeiraPosicao(indexAtual: number, array: any[]): any {
        const posicaoAtual = this.retornaIndex(indexAtual, array);
        if (posicaoAtual > 0) {
            return array[0];
        }
    }

    public avancarProximaPosicao(indexAtual: number, array: any[]): any {
        const posicaoAtual = this.retornaIndex(indexAtual, array);
        if (posicaoAtual < array.length - 1) {
            return array[posicaoAtual + 1];
        }
    }

    public avancarUltimaPosicao(indexAtual: number, array: any[]): any {
        const posicaoAtual = this.retornaIndex(indexAtual, array);
        if (posicaoAtual < array.length - 1) {
            return array[array.length - 1];
        }
    }

    public desabilitaBotoesAnteriores(indexAtual: number, array: any[]): boolean {
        const posicaoAtual = this.retornaIndex(indexAtual, array);
        if (posicaoAtual === 0) {
            return true;
        }
        if (posicaoAtual > 0) {
            return false;
        }
    }

    public desabilitaBotoesProximos(indexAtual: number, array: any[]): boolean {
        const posicaoAtual = this.retornaIndex(indexAtual, array);
        if (posicaoAtual === array.length - 1) {
            return true;
        }
        if (posicaoAtual < array.length - 1) {
            return false;
        }
    }
    public desabilitaBotaoPlay(indexAtual: number, array: any[]): boolean {
        const posicaoAtual = this.retornaIndex(indexAtual, array);
        if (posicaoAtual === array.length) {
            return false;
        }
        if (posicaoAtual < array.length) {
            return true;
        }
    }

    public retornaIndex(indexLeitura: any, array: any[]): number {
        let index = null;
        array.forEach((item, position) => {
            if (indexLeitura === item.indexLeitura) {
                index = position;
            }
        });
        return index;
    }
}
