<div id="tooltipCustomPendulo" class="absolute z-99 rounded popoverCable" style="width: 200px; height: 80px">
    <div class="w-full bg-slate-200">
        <span *ngIf="penduloAtual">{{ "P" + (penduloAtual?.codigo | number : "2.0") }}</span>
    </div>
    <div class="w-full bg-slate-100 text-sm p-1.5 text-left">
        <p>Tipo: {{ penduloAtual?.pendulo_propriedade?.digital_termopar ?? "Sem registros." }}</p>
        <p>Equip.: {{ equipamentoAtual(penduloAtual)?.nome ?? "Sem registros." }}</p>
        <span> Status: </span>
        <span>
            {{
                equipamentoAtual(penduloAtual)?.instante
                    ? (equipamentoAtual(penduloAtual)?.instante | date : "dd/MM/yyyy HH:mm")
                    : "Sem registros."
            }}
        </span>
        <mat-icon id="icontooltipCustomPendulo" svgIcon="mat_solid:circle"></mat-icon>
    </div>
</div>
