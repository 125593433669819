import { ComunicacaoInfoModel, PenduloInterface, Temperatura } from 'app/shared/models';
import { SiloBaseEnum } from 'app/shared/enums';
import { VariacaoTemperaturaInterface } from '../interfaces/variacao-temperatura.interface';

export class SiloVisaoDetalhadaModel {
    id: number;
    semDados: boolean;
    arquivado: boolean;
    comunicacaoInfo: ComunicacaoInfoModel;
    contemTemperaturaSensores: boolean;
    digitalTermopar: string;
    nivelSensoresTemperatura: string;
    nivelSensoresUmidade: string;
    nome: string;
    pendulos: PenduloInterface;
    quantidadeSensoresTemperatura: string;
    quantidadeSensoresUmidade: string;
    equilibrioHigroscopicoEhamb: string;
    sequencia: number;
    siloBase: SiloBaseEnum;
    statusComunicacao: string;
    suportePenduloCentral: boolean;
    temperaturaSensores: string;
    umidadeSensores: string;
    produto_ref: number;
    variacaoTemperatura: VariacaoTemperaturaInterface;
    variacaoUmidade: Temperatura;
    sensor_umidade_instalado: boolean;
}
