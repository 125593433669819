export class FormularioModalRetornoModel {
    diaSemana?: any;
    operador?: any;
    tipoVariavelDireita?: any;
    tipoVariavelEsquerda?: any;
    valorDireita?: number;
    valorEsquerda?: number;
    variavelDireita?: any;
    variavelEsquerda?: any;
    horarioInicial?: any;
    horarioFinal?: any;
    constructor(partial?: Partial<FormularioModalRetornoModel>) {
        Object.assign(this, partial);
    }
}
