export enum ErrosApiEnum {
    REGRA_MENSAGEM_ARQUIVAR_ERRO = 'Não foi possível arquivar a regra. Por favor tente novamente.',
    REGRA_MENSAGEM_DESARQUIVAR_ERRO = 'Não foi possível desarquivar a regra. Por favor tente novamente.',
    REGRA_MENSAGEM_DUPLICAR_ERRO = 'Não foi possível duplicar a regra. Por favor tente novamente.',
    REGRA_MENSAGEM_EXPORTAR_ERRO = 'Não foi possível exportar a regra. Por favor tente novamente.',
    REGRA_MENSAGEM_SALVAR_ERRO = 'Não foi possivel salvar a regra.',
    PROGRAMA_MENSAGEM_SALVAR_ERRO = 'Não foi possivel salvar o programa.',
    PROGRAMA_MENSAGEM_ARQUIVAR_ERRO = 'Não foi possível arquivar o programa. Por favor tente novamente.',
    PROGRAMA_MENSAGEM_DESARQUIVAR_ERRO = 'Não foi possível desarquivar o programa. Por favor tente novamente.',
    PROGRAMA_MENSAGEM_DUPLICAR_ERRO = 'Não foi possível duplicar o programa. Por favor tente novamente.',
    PROGRAMA_MENSAGEM_EXPORTAR_ERRO = 'Não foi possível exportar o programa. Por favor tente novamente.',
    GRAO_MENSAGEM_ARQUIVAR_ERRO = 'Não foi possível arquivar o grão. Por favor tente novamente.',
    GRAO_MENSAGEM_DESARQUIVAR_ERRO = 'Não foi possível desarquivar o grão. Por favor tente novamente.',
    GRAO_MENSAGEM_SALVAR_ERRO = 'Não foi possível salvar o grão. Por favor tente novamente.',
    CONTROLE_ALTERADO_ERRO = 'Não foi possível alterar o controle da aeração.',
    PROGRAMA_ALTERADO_ERRO = 'Não foi possível alterar o programa da aeração.',
    VARIAVEL_ALTERADA_ERRO = 'Não foi possível alterar a variável de aquecimento.',
}
