export class SiloComVolumeModel {
    id: number;
    nome: string;
    codigo: number;
    arquivado: boolean;
    sensores_volume: SensorVolume[];
}

export class SensorVolume {
    id: number;
    arquivado: boolean;
    nome: string;
}
