import { Injectable } from '@angular/core';
import moment from 'moment';

export abstract class IRetonaInstanteMaisRecenteEquipamentoService {
    abstract execute(instantes: string[]): string;
}

@Injectable({
    providedIn: 'root',
})
export class RetonaInstanteMaisRecenteEquipamentoService {
    constructor() {}

    execute(instantes: string[]): string {
        const datas = [];
        instantes?.forEach((instante) => {
            if (instante) {
                datas.push(moment(instante));
            }
        });
        if (moment.max(datas).isValid()) {
            return moment.max(datas)?.format();
        } else {
            return 'Sem registros';
        }
    }
}
