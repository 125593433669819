export class SincronizadorModel {
    coletor: {
        instante: string;
        status: boolean;
    };
    local_nuvem: {
        instante: string;
        status: boolean;
    };
}

export class HistoricoSincronizadorModel {
    id: number;
    instante: string;
    status: boolean;
}
