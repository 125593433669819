import { BehaviorSubject, Observable } from 'rxjs';
import { AmostragemEstadoModel } from 'app/shared/models';

export class SituacaoObservable {
    private estadoComunicacao: BehaviorSubject<AmostragemEstadoModel> = new BehaviorSubject(null);

    get estado$(): Observable<AmostragemEstadoModel> {
        return this.estadoComunicacao.asObservable();
    }

    set estado(valor: AmostragemEstadoModel) {
        this.estadoComunicacao.next(valor);
        this.botaoAdicionarEstaVisivel(valor);
    }

    botaoAdicionarEstaVisivel(estado: any): boolean {
        if (estado === 1) {
            return true;
        }
        return false;
    }
}
