<div class="d-flex componente-modal w-full">
    <div class="d-flex mb-5 modal-botao-fechar">
        <mat-icon id="icon-close" (click)="dialogRef.close()">close</mat-icon>
    </div>

    <div class="d-flex text-center pb-lg-0 mt-2 modal-titulo">
        <p class="text-base sm:text-2xl">Histórico de execução do serviço do {{dialogData.tipo}}</p>
    </div>

    <div class="componente-tabela-modal mb-5 mt-5 sm:mt-8 overflow-hidden overflow-y-auto max-h-60">
        <ng-container *ngIf="!carregando; else carrgandoTabela">
            <table mat-table [dataSource]="historico">
                <ng-container matColumnDef="data">
                    <th mat-header-cell width="50%" *matHeaderCellDef class="text-sm sm:text-base">Data/Hora</th>
                    <td mat-cell *matCellDef="let element" class="text-center text-sm sm:text-base">
                        {{ element.instante ? (element.instante | date: 'dd/MM/yyyy HH:mm:ss') : 'Sem registros' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell width="50%" *matHeaderCellDef class="text-sm sm:text-base">Status</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="tabela-status">
                            <span
                                [ngClass]="{
                                communicating: element.status,
                                'non-communicating': !element.status
                                }"
                            >
                                <p *ngIf="element.status" class="text-sm sm:text-base">Em execução</p>
                                <p *ngIf="!element.status" class="text-sm sm:text-base">Sem comunicação</p>
                            </span>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="tabelaColunas"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: tabelaColunas"></tr>
            </table>
        </ng-container>
    </div>

    <div class="flex justify-center" *ngIf="!carregando && historico.length > 0">
        <button
            class="action wl-bg-cor-secundaria border-none text-sm sm:text-base"
            (click)="carregarProximosRegistros()"
            [disabled]="desabilitaCarregarMais()"
        >
            Carregar mais
        </button>
    </div>
</div>
<ng-template #carrgandoTabela>
    <div class="h-[200px] flex">
        <span class="m-auto flex">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
        </span>
    </div>
</ng-template>
<ng-template [ngIf]="!carregando && historico.length == 0">
    <div class="h-[200px] flex">
        <span class="m-auto flex"> Não há registros referente ao equipamento. </span>
    </div>
</ng-template>
