<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<app-aside-menu></app-aside-menu>

<div class="flex flex-col flex-auto w-full min-w-0 pl-0 sm:pl-[75px] cursor-default">
    <div id="sobreposicao" (click)="classicService.fecharMenuSobreposto()"></div>
    <div
        class="relative flex flex-0 items-center w-full h-[53.17px] sm:h-16 md:px-5 z-49 shadow dark:shadow-none dark:border-b sm:bg-card dark:bg-transparent print:hidden border-t-2 border-[#535767] bg-[#00091F]"
    >
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'" class="text-[#bac3d2] sm:text-[#64748b]"></mat-icon>
        </button>
        <span class="font-semibold hidden sm:block">Exibir menu</span>
        <div class="flex sm:w-[75%] w-[100%] items-center justify-center">
            <app-header-select-customer (trocaUnidade)="trocaUnidade($event)"></app-header-select-customer>
        </div>
        <div class="flex items-center pl-0 sm:pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <user></user>
        </div>
    </div>
    <ng-container *ngIf="!recarregarComponente">
        <div
            class="sm:flex-row flex-col flex items-center w-full h-auto sm:h-16 shadow dark:shadow-none bg-card dark:bg-transparent md:justify-between print:hidden subHeader md:px-4"
        >
            <div class="text-stone-50 py-2 sm:py-0">
                <app-page-info></app-page-info>
            </div>
            <div
                class="w-full sm:w-auto bg-card py-2 sm:py-0 border-[#F4F6F9] border-t-2 sm:border-0"
                *ngIf="habilitaSicronizador()"
            >
                <app-sincronizador-equipamento class="w-full"> </app-sincronizador-equipamento>
            </div>
        </div>
        <div class="flex flex-col flex-auto">
            <router-outlet *ngIf="true"></router-outlet>
        </div>
        <div
            class="relative flex flex-0 justify-between items-center w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
        >
            <span class="font-medium text-secondary">Procer &copy; {{ currentYear }} </span>
            <span class="w-[93px] h-[35px] flex items-center"><img [src]="logoUrl" /></span>
        </div>
    </ng-container>
</div>

<div class="alerta-fixo" *ngIf="alerta?.mostrar">
    <fuse-alert *ngIf="alerta?.mostrar" class="mt-0 -mr-0" [showIcon]="true" [type]="alerta.tipo" [appearance]="'fill'">
        <span fuseAlertTitle>{{ alerta.titulo }}</span>
        <span>{{ alerta.mensagem }}</span>
    </fuse-alert>
</div>
