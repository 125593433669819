import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthModule } from 'app/core/auth/auth.module';
import { IconsModule } from 'app/shared/icons/icons.module';
import { AsideMenuModule } from './aside-menu/aside-menu.module';

@NgModule({
    imports: [AuthModule, IconsModule, AsideMenuModule],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
