import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { CanFirstAccessGuard, CanUseSystemGuard } from 'app/modules/auth/guards';

export const appRoutes: Route[] = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'armazenagem-visualizacao/painel-geral',
    },

    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'armazenagem-visualizacao/painel-geral',
    },

    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
            },
            {
                path: 'mapa-contatos',
                loadChildren: () =>
                    import('app/modules/auth/mapa-contatos/mapa-contatos.module').then((m) => m.MapaContatosModule),
            },
        ],
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
            },
        ],
    },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'page-builder',
                loadChildren: () =>
                    import('app/modules/landing/page-builder/page-builder.module').then((m) => m.PageBuilderModule),
            },
        ],
    },
    {
        path: '',
        canActivateChild: [AuthGuard, CanFirstAccessGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'first-access',
                loadChildren: () =>
                    import('app/modules/auth/first-access/first-acess.module').then((m) => m.AuthFirstAccessModule),
            },
        ],
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard, CanUseSystemGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'armazenagem-visualizacao',
                loadChildren: () =>
                    import('app/modules/armazenagem/visualizacao/visualizacao.module').then(
                        (m) => m.ArmazenagemVisualizacaoModule
                    ),
            },
            {
                path: 'armazenagem-analise',
                loadChildren: () =>
                    import('app/modules/armazenagem/analise/analise.module').then((m) => m.ArmazenagemAnaliseModule),
            },
            {
                path: 'armazenagem-cadastro',
                loadChildren: () =>
                    import('app/modules/armazenagem/cadastro/cadastro.module').then((m) => m.ArmazenagemCadastroModule),
            },
            {
                path: 'armazenagem-configuracao',
                loadChildren: () =>
                    import('app/modules/armazenagem/configuracao/configuracao.module').then(
                        (m) => m.ArmazenagemConfiguracaoModule
                    ),
            },
        ],
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'secador',
                loadChildren: () =>
                    import('app/modules/secador/visualizacao/visualizacao.module').then(
                        (m) => m.SecadorVisualizacaoModule
                    ),
            },
        ],
    },
];
