import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { map, Observable } from 'rxjs';
import { UnidadeParametrizacaoObservable } from 'app/shared/observables';
import { UnidadeInterface } from 'app/shared/interfaces';

export abstract class IUnidadeParametrizacaoController {
    abstract buscaParametrosUnidade(): Observable<UnidadeInterface>;
}

@Injectable({
    providedIn: 'root',
})
export class UnidadeParametrizacaoController implements IUnidadeParametrizacaoController {
    private transport: IApiTransportInterface;

    constructor(
        private unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable,
        readonly apiTransportService: ApiTransportService
    ) {
        this.transport = this.apiTransportService.execute('/unidade/parametrizacao/produtos');
    }

    buscaParametrosUnidade(): Observable<UnidadeInterface> {
        return this.transport.http.get<UnidadeInterface>(`${this.transport.path}`).pipe(
            map((response) => {
                this.unidadeParametrizacaoObservable.setParametrosUnidade = response;
                return response;
            })
        );
    }
}
