export class BindableProperty<T> {
    ativo = false;
    value: T;
    instante: string;
    penduloPropriedades: any;
}

export class RainfallProperty extends BindableProperty<number> {
    raining: boolean;
}
