import { Injectable } from '@angular/core';
import moment from 'moment';
import { formataDataComHora } from 'app/shared/constants';

export abstract class IRetonaInstanteMaisRecenteAeradoresService {
    abstract execute(aeradores: any[], statusCode: any): string;
}

@Injectable({
    providedIn: 'root',
})
export class RetonaInstanteMaisRecenteAeradoresService implements IRetonaInstanteMaisRecenteAeradoresService {
    constructor() {}

    execute(aeradores: any[], statusCode: any): string {
        let datas = [];
        switch (statusCode) {
            case '0A':
            case '0B':
                datas = aeradores?.map((aerador) =>
                    moment(
                        aerador.aerador_leitura_chave.equipamento_comunicacao.instante ||
                            aerador.aerador_leitura_rele.equipamento_comunicacao.instante ||
                            aerador.aerador_leitura_status.equipamento_comunicacao.instante
                    )
                );
            case '1A':
            case '1B':
                datas = aeradores?.map((aerador) =>
                    aerador.aerador_leitura_rele.equipamento_comunicacao.instante
                        ? moment(aerador.aerador_leitura_rele.equipamento_comunicacao.instante)
                        : ''
                );
            case '2A':
            case '2B':
                datas = aeradores?.map((aerador) =>
                    aerador.aerador_leitura_chave.equipamento_comunicacao?.instante
                        ? moment(aerador.aerador_leitura_chave.equipamento_comunicacao?.instante)
                        : ''
                );
            case '3A':
            case '3B':
                datas = aeradores?.map((aerador) =>
                    aerador.aerador_leitura_status.equipamento_comunicacao.instante
                        ? moment(aerador.aerador_leitura_status.equipamento_comunicacao.instante)
                        : ''
                );
                return datas.filter((datas) => datas != '').length > 0
                    ? moment
                          .max(datas.filter((datas) => datas != ''))
                          ?.locale(formataDataComHora.locale)
                          .format(formataDataComHora.formato)
                    : '';
        }
    }
}
