export interface InformacoesGeraisSiloSensores {
    variacaoTemperatura: SensoresTemperatura;
    variacaoUmidade: Temperatura;
}
export interface SensoresTemperatura {
    geral: Temperatura;
    digital: Temperatura;
    termopar: Temperatura;
}

export interface Temperatura {
    min: number;
    max: number;
    media: number;
}

// Estoque
export interface InformacoesGeraisSiloEstoque {
    data: DataEstoque[];
    total: number;
}

export interface DataEstoque {
    tonelada: Tonelada;
    saca: Saca;
}

export interface Tonelada {
    capacidadeEstoque: number;
    estoqueEstimado: number;
    estoqueInformado: number;
}

export interface Saca {
    capacidadeEstoque: number;
    estoqueEstimado: number;
    estoqueInformado: number;
}

// Grão
export interface InformacoesGeraisSiloGraoModel {
    data: DataGrao[];
    total: number;
}

export interface DataGrao {
    produto: Produto;
    amostragem: Amostragem;
}

export interface Produto {
    grao: string;
    tipo: string;
    produto_tipo_ref: number;
}

export interface Amostragem {
    id: number;
    estrutura_amostragem_ref: number;
    estrutura_amostragem_tipo: string;
    usuario_ref: number;
    instante_inclusao: string;
    data_amostra: string;
    umidade_controle: number;
    impureza_controle: number;
    avariados_controle: number;
    notas: string;
}
