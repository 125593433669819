import {
    FormularioModalRetornoModel,
    VariavelControleModel,
} from 'app/modules/armazenagem/cadastro/regras/cadastro/models';

export class DataModalFormulasModel {
    formulaEditar: FormularioModalRetornoModel;
    listaVariaveis?: VariavelControleModel[];

    constructor(partial?: Partial<DataModalFormulasModel>) {
        Object.assign(this, partial);
    }
}
