import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

import {
    ComunicacaoColetorController,
    IComunicacaoColetorController,
    ComunicacaoNuvemController,
    IComunicacaoNuvemController,
    SincronizadorEquipamentoHistoricoComponent,
} from 'app/layout/common/sincronizador-equipamento-historico';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    declarations: [SincronizadorEquipamentoHistoricoComponent],
    imports: [CommonModule, MatIconModule, MatTableModule, MatProgressSpinnerModule, TranslocoRootModule],
    exports: [SincronizadorEquipamentoHistoricoComponent],
    providers: [
        { provide: IComunicacaoColetorController, useClass: ComunicacaoColetorController },
        { provide: IComunicacaoNuvemController, useClass: ComunicacaoNuvemController },
    ],
})
export class SincronizadorEquipamentoHistoricoModule {}
