import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { InformacaoesPaginaObservable } from 'app/shared/observables';
import { Subject, takeUntil } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-mensagem-celular',
    templateUrl: './modal-mensagem-celular.component.html',
    styleUrls: ['./modal-mensagem-celular.component.scss'],
})
export class ModalMensagemCelularComponent implements OnInit {
    menuSelecionado: string = '';
    subMenuSelecionado: string = '';
    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private pageInfo: InformacaoesPaginaObservable,
        private changeDetectorRef: ChangeDetectorRef,
        public readonly dialogRef: MatDialogRef<ModalMensagemCelularComponent>,
    ) {}

    ngOnInit(): void {
        this.setPageInfo();
    }

    private setPageInfo(): void {
        this.pageInfo.menuSelecionadoModal$.pipe(takeUntil(this.unsubscribeAll)).subscribe((menu: any) => {
            this.menuSelecionado = menu;
            this.changeDetectorRef.markForCheck();
        });
        this.pageInfo.subMenuSelecionadoModal$.pipe(takeUntil(this.unsubscribeAll)).subscribe((sub: any) => {
            this.subMenuSelecionado = sub;
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
