export class StatusAeracaoModel {
    codigo?: any;
    icone?: {
        tipo?: string;
        texto?: string;
        classIcone?: string;
        modal?: {
            titulo?: string;
            subtitulo?: string;
            mensagem?: string;
        };
    };
    alerta: {
        cor?: string;
        background?: string;
    };
    aerador?: {
        cor?: string;
    };
    acionamento_manual?: {
        habilitado?: boolean;
        modal?: {
            titulo?: string;
            subtitulo?: string;
            mensagem?: string;
            botaoAcao?: string;
        };
    };

    constructor(partial?: Partial<StatusAeracaoModel>) {
        Object.assign(this, partial);
    }
}
