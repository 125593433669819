export class RegraPayloadModel {
    nome: string;
    expressao_ligar: string;
    expressao_manter_ligado: string;
    notas?: string;
    usuario_ref?: number;

    constructor(partial?: Partial<RegraPayloadModel>) {
        Object.assign(this, partial);
    }
}
