import { TipoVisualizacaoSiloApiEnum } from 'app/shared/enums';

export class OpcoesVisualizacaoSilo {
    roofOpenType: number;
    sensorSpacing: string;
    roofBottom: boolean;
    redefineCablesOrder: string;
    roofSensors: string;
    siloList: any;
    viewType: TipoVisualizacaoSiloApiEnum;
    file: string;
}
